import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AuthenticationService} from '../auth/authentication.service';
import {map, shareReplay} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AppNavigationService} from '../app-navigation/app-navigation.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnChanges {
  constructor(private authenticationService: AuthenticationService,
              private breakpointObserver: BreakpointObserver,
              private navigationService: AppNavigationService) {
    // this.authenticationService.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  // public itemCounter = 0;
  // public favoritesCounter = 0;
  // searchTextField: string;

  ngOnChanges(changes: SimpleChanges): void {
    // this.itemCounter = changes[this.itemCounter];
    // this.favoritesCounter = changes[this.favoritesCounter];
  }

  // onSearch() {
  //   console.log('randomText', this.searchTextField);
  // }

  // readFavoritesQuantity() {
  //   this.favoritesCounter = this.navigationService.readFavoriteQuantity();
  //   return this.favoritesCounter;
  // }

  // readCartsQuantity() {
  //   this.itemCounter = this.navigationService.readCartQuantity();
  //   return this.itemCounter;
  // }

  ngOnInit() {
    // this.readFavoritesQuantity();
    // this.readCartsQuantity();
  }

}

