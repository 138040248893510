export class Contacts {
  id: number;
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  coordinates?: CoordinatesEntity;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  description: string;
  isPrimary: boolean;
}

export interface CoordinatesEntity {
  latitude: number;
  longitude: number;
}
