<div class="container pb-5 mb-2 mb-md-4">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="contactForm" novalidate (ngSubmit)="sendInfo()">
        <!--<mat-card class="shipping-card card">-->
        <div class="card">
          <div class="card-header card-header-indigo">
            <h4 class="card-title">Contact form</h4>
            <p class="card-category">Please fill correct your shipping address</p>
          </div>
          <div class="card-body">
            <!--<form>-->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field class="full-width">
                    <mat-label>First name*</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-error *ngIf="contactForm.controls['firstName'].hasError('required')">
                      First name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field class="full-width">
                    <mat-label>Last name*</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-error *ngIf="contactForm.controls['lastName'].hasError('required')">
                      Last name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field class="full-width">
                    <mat-label>Email address*</mat-label>
                    <input type="email" matInput value="puhiny@abv.bg" formControlName="email">
                    <mat-error *ngIf="contactForm.controls['email'].hasError('required')">
                      Email must be a valid email address<strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field class="full-width">
                    <mat-label>Phone number*</mat-label>
                    <input #phoneNumber matInput value="" type="number" formControlName="phoneNumber">
                    <mat-hint align="end">{{phoneNumber.value.length}} / 15</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <!--<mat-form-field class="full-width">-->
                    <!--<mat-label>Additional Information</mat-label>-->
                    <!--<textarea matInput placeholder="Ex. It makes me feel..." rows="3"></textarea>-->
                  <!--</mat-form-field>-->

                  <!--<label>About Me</label>-->
                  <!--<div class="form-group">-->
                  <!--<label class="bmd-label-floating"> Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat-->
                  <!--Lambo.</label>-->
                  <!--<textarea class="form-control" rows="5"></textarea>-->
                  <!--</div>-->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <mat-form-field class="full-width">
                    <mat-label>Address start typing or click on the map</mat-label>
                    <input matInput value="5 route" formControlName="address" id="pac-input"
                           (keydown.enter)="$event.preventDefault()" #addressSearch>


                  </mat-form-field>
                </div>
              </div>
            </div>

            <button mat-raised-button color="primary" class="pull-right" type="submit">Send </button>
            <!--<button type="submit" class="btn btn-primary pull-right">Update Profile</button>-->
            <div class="clearfix"></div>
            <!--</form>-->
          </div>
        </div>
        <!--</mat-card>-->
      </form>
    </div>

  </div>

  <button (click)="addMarker()">Add marker</button>
  <img src="assets/icons/blue-dot.png">

  <!--<app-google-map></app-google-map>-->

<!--  <google-map-->
<!--    height="500px"-->
<!--    width="100%"-->
<!--    [zoom]="zoom"-->
<!--    [center]="center"-->
<!--    [options]="options"-->
<!--    (mapClick)="click($event)"-->
<!--  >-->
<!--    <map-marker-->
<!--      #markerElem-->
<!--      *ngFor="let marker of markers"-->
<!--      [position]="marker.position"-->
<!--      [label]="marker.label"-->
<!--      [title]="marker.title"-->
<!--      [options]="marker.options"-->
<!--      [icon]=""-->
<!--      (mapClick)="openInfo(markerElem, marker.info, marker.title)"-->
<!--      (mapDragend)="markerDragEnd($event)"-->
<!--    >-->
<!--    </map-marker>-->

<!--    <map-info-window>{{ infoContent }}</map-info-window>-->
<!--  </google-map>-->

</div>





