import {Injectable} from '@angular/core';
import {ItemView} from '../app-item/ItemView';
import {DataService} from '../_layout/data.service';
import {ColorDto} from '../admin/admin-item/ColorDto';
import {SizeDto} from '../admin/admin-item/SizeDto';
import {AppMessageService} from '../message/app-message.service';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ItemColorVariation} from '../admin/admin-item/ItemColorVariation';
import {AppStorageService} from '../storageService/app-storage.service';

const favoriteUrl = environment.apiEndpoint + environment.apiProtectedRootUrl + '/favorite';

@Injectable({providedIn: 'root'})
export class AppFavoriteService {

  constructor(private http: HttpClient,
              private dataService: DataService,
              private messageService: AppMessageService,
              private appStorageService: AppStorageService) {}

  favoriteItems: ItemView[] = [];
  favoriteStoreItems = new Map<string, ItemView[]>();

  addFavoriteService(itemId: number, colorId: number, storeId: number): Observable<void> {
    console.log('favoriteUrl/', itemId + '/' + colorId + '/' + storeId);
    return this.http.post<void>(favoriteUrl + '/' + itemId + '/' + colorId + '/' + storeId, {});
  }
  addFavoriteServiceObject(itemId: number, colorId: number, storeId: number): Observable<void> {
    console.log('favoriteUrl/', itemId + '/' + colorId + '/' + storeId);
    return this.http.post<void>(favoriteUrl + '/' + itemId + '/' + colorId + '/' + storeId, {});
  }
  addFavorite(addItem, variationIdx) {
    if (!addItem.itemVariations[variationIdx].favorite) {
      addItem.itemVariations[variationIdx].favorite = false;
    }
    addItem.itemVariations[variationIdx].favorite = !addItem.itemVariations[variationIdx].favorite;
    addItem.store = addItem.itemVariations[0].stores[0];

    const itemVar = addItem.itemVariations[variationIdx];
    const itemInFavorite = {
      id: addItem.id,
      name: addItem.name,
      brand: addItem.brand,
      store: addItem.store,
      translations: addItem.translations,
      shippingCompanies: null,
      shipping: null,
      itemVariations: [{
        favorite: itemVar.favorite,
        stores: [addItem.store],
        selectedItemColor: itemVar.selectedItemColor,
        fileInfos: itemVar.fileInfos,
        itemSizeFormArray: itemVar.itemSizeFormArray,
      }]
    };

    console.log('addFavorite___', addItem, variationIdx);
    this.messageService.info($localize`The product has been added to your favorite.`);
    this.addToFavoriteAccordingStore(itemInFavorite, variationIdx);
    this.setFavoriteItemsToLocalStorage();
    this.calculateTotalItemsInFavorite();
  }

  removeFavorite(storeId: string,
                 itemId: number,
                 colorItm: ColorDto, colorInd: number,
                 sizeItm: SizeDto, sizeInd: number) {
    const existItems = this.favoriteStoreItems.get(storeId);
    const itemFindIndex = existItems.findIndex(it => it.id === itemId);
    const itemFind = existItems.find(it => it.id === itemId);

    // const colorFind = itemFind.itemVariations.find(color => color.selectedItemColor.id === colorItm.id);
    const colorIndex = itemFind.itemVariations.findIndex(color => color.selectedItemColor.id === colorItm.id);
    itemFind.itemVariations.splice(colorIndex, 1);

    if (itemFind.itemVariations.length === 0) {
      existItems.splice(itemFindIndex, 1);
    }
    if (existItems.length === 0) {
      this.favoriteStoreItems.delete(storeId);
    }



    this.setFavoriteItemsToLocalStorage();
    this.calculateTotalItemsInFavorite();
  }

  removeFavoriteService(itemId: number, colorId: number, storeId: number): Observable<void> {
    console.log('remove favorite: ', itemId + '/' + colorId + '/' + storeId);
    return this.http.delete<void>(favoriteUrl + '/' + itemId + '/' + colorId + '/' + storeId);
  }

  findFavorites() {
    return this.getFavoriteItemsFromLocalStorage();
  }
  findFavoriteServiceQuery(language: string): Observable<ItemView[]> {
    console.log('favoriteUrl/', language);
    return this.http.get<ItemView[]>(favoriteUrl + '/' + language);
  }
  findFavoriteServiceSubscription(language: string, username: string) {
    this.findFavoriteServiceQuery(language)
      .subscribe({
        next: favItems => {
          this.setFavoriteItemsToLocalStorageService(favItems, username);
          this.favoriteItems = favItems;
          this.calculateTotalItemsInFavoriteService();
        },
        error: err => {
          console.log(err);
        }
      });
  }

  addToFavoriteQuery(item: ItemView, variation: ItemColorVariation, username: string) {

    this.addFavoriteService(item.id, variation.selectedItemColor.id, variation.itemSizeFormArray[0].stock.store.id)
      .subscribe({
        next: value => {
          this.addToFavorite(item, variation, username);
          this.messageService.info($localize`The product has been added to your favorite.`);
        },
        error: err => {
          console.log(err);
        }
      });
  }

  removeFromFavoriteQuery(item: ItemView, variation: ItemColorVariation, username: string) {
    this.removeFavoriteService(item.id, variation.selectedItemColor.id, variation.itemSizeFormArray[0].stock.store.id)
      .subscribe({
        next: removed => {
          this.removeFromFavorite(item, variation, username);
          this.messageService.info($localize`Item removed from favorites`);
        },
        error: err => {
          console.log(err);
        }
      });
  }

  _findAddItemToFavorites(item: ItemView, variation: ItemColorVariation) {
    if (this.favoriteItems) {
      const itemFound = this.favoriteItems.find(itm => itm.id === item.id);
      if (itemFound) {
        if (!itemFound.itemVariations) {
          itemFound.itemVariations = [];
        }
        itemFound.itemVariations.push(variation);
      }
      return itemFound;
    }
    return '';
  }

  _filterItemAndVariation(item: ItemView, variation: ItemColorVariation) {
    const itemCopy = JSON.parse(JSON.stringify(item));
    const foundedVar = item.itemVariations.filter(va => va.selectedItemColor.id === variation.selectedItemColor.id);
    itemCopy.itemVariations = [];
    itemCopy.itemVariations.push(variation);
    console.log('5555555', itemCopy);
    return itemCopy;
  }



  // clearFavorites() {
  //   this.favoriteItems = [];
  //   return this.favoriteItems;
  // }

  readQuantity() {
    // this.favoriteStoreItems =
    // if (this.favoriteStoreItems) {
    //   // this.itemCounter = this.favoriteStoreItems.length;
    // }
    return this.calculateTotalItemsInFavorite();
  }

  readFavorites() {
    return this.getFavoriteItemsFromLocalStorage();
  }

  // setItemsToLocalStorage() {
  //   localStorage.setItem('favoriteItem', JSON.stringify(this.favoriteItems));
  // }
  //
  // setItemsFromLocalStorage() {
  //   // if (this.favoriteItems.length === 0) {
  //   const itemsLocalStorage = localStorage.getItem('favoriteItem');
  //   if (itemsLocalStorage) {
  //     this.favoriteItems = JSON.parse(itemsLocalStorage);
  //   }
  //
  //   // }
  //   console.log('ffavoriteItems___finAll', this.favoriteItems);
  //   return this.favoriteItems;
  // }

  checkIfExistFavorites(itm) {
    // if (this.favoriteItems) {
    //   for (const fav of this.favoriteItems) {
    //     if (itm.id === fav.id) {
    //       return true;
    //     }
    //   }
    // }
    return false;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log('changeeessssss', changes);
  //   // this.itemCounter = changes[this.itemCounter];
  //   // this.favoritesCounter = changes[this.favoritesCounter];
  // }

  setFavoriteItemsToLocalStorage() {
    console.log('se store items in cart', this.favoriteStoreItems);
    localStorage.setItem('favoriteStoreItems', JSON.stringify(Object.fromEntries(this.favoriteStoreItems)));
  }

  getFavoriteItemsFromLocalStorage() {
    // if (!this.favoriteStoreItems || this.favoriteStoreItems.size === 0) {
      const favStoredItems = localStorage.getItem('favoriteStoreItems');
      if (favStoredItems) {
        this.favoriteStoreItems = new Map(Object.entries(JSON.parse(favStoredItems)));
      }
    // }
    console.log('favorite Store Items', this.favoriteStoreItems);
    return this.favoriteStoreItems;
  }

  setFavoriteItemsToLocalStorageService(favoriteItems: ItemView[], username: string) {
    this.favoriteItems = favoriteItems;
    if (!this.favoriteItems || this.favoriteItems.length === 0) {
      this.favoriteItems = [];
    }
    localStorage.setItem(this.constructStorageFavoriteName(username), JSON.stringify(this.favoriteItems));
  }
  getFavoriteItemsFromLocalStorageService(username: string): ItemView[] {
    const favStoredItems = localStorage.getItem(this.constructStorageFavoriteName(username));
    if (favStoredItems) {
      return JSON.parse(favStoredItems);
    }
    return null;
  }

  addToFavorite(item: ItemView, variation: ItemColorVariation, username: string) {
    const itemAdded = this._findAddItemToFavorites(item, variation);
    if (!itemAdded) {
      if (!this.favoriteItems) {
        this.favoriteItems = [];
      }
      const itemCopy = this._filterItemAndVariation(item, variation);
      this.favoriteItems.push(itemCopy);
    }
    this.setFavoriteItemsToLocalStorageService(this.favoriteItems, username);
    this.calculateTotalItemsInFavoriteService();
  }
  getFavoriteItems(username: string) {
    // if (!this.favoriteItems || this.favoriteItems.length === 0) {
      this.favoriteItems = this.getFavoriteItemsFromLocalStorageService(username);
    // }
    return this.favoriteItems;
  }
  removeFromFavorite(item: ItemView, variation: ItemColorVariation, username: string) {
    const itemIndexFound = this.favoriteItems.findIndex(itm => itm.id === item.id);
    const itemFound = this.favoriteItems[itemIndexFound];
    if (itemIndexFound >= 0 ) {
      const vaIndex = itemFound.itemVariations.findIndex(va => va.selectedItemColor.id === variation.selectedItemColor.id);
      if (vaIndex >= 0) {
        itemFound.itemVariations.splice(vaIndex, 1);
      }
      if (!itemFound.itemVariations || itemFound.itemVariations.length === 0) {
        this.favoriteItems.splice(itemIndexFound, 1);
      }
    }
    this.setFavoriteItemsToLocalStorageService(this.favoriteItems, username);
    this.calculateTotalItemsInFavoriteService();
    return this.favoriteItems;
  }

  addToFavoriteAccordingStore(addItem: ItemView, variationIdx: number) {
    let existItems = this.favoriteStoreItems.get(JSON.stringify(addItem.store.id));
    if (!existItems) {
      existItems = [];

      existItems.push(addItem);
      this.favoriteStoreItems.set(JSON.stringify(addItem.store.id), existItems);

      return;
    }
    const itemFind = existItems.find(it => it.id === addItem.id);
    // const colorFind = itemFind.itemVariations.find(color => item.itemVariations.includes(color));
    // const sizeFind = colorFind.itemVariations.find(size => item.itemVariations.includes(size));

    if (itemFind) {
      let colorExist = false;
      for (const color of itemFind.itemVariations) {
        for (const variation of addItem.itemVariations) {
          if (color.selectedItemColor.name === variation.selectedItemColor.name) {
            colorExist = true;
          }
        }
      }
      if (!colorExist) {
        itemFind.itemVariations.push(addItem.itemVariations[0]);
      }
    }  else {
      existItems.push(addItem);
    }
  }

  calculateTotalItemsInFavorite() {
    this.getFavoriteItemsFromLocalStorage();
    let counter = 0;
    for (const favoritesPerStore of this.favoriteStoreItems.values()) {
      for (const favorite of favoritesPerStore) {
        for (const color of favorite.itemVariations) {
          counter += color.itemSizeFormArray.length;
        }
      }
    }
    this.dataService.sendFavData(counter);
    return counter;
  }

  calculateTotalItemsInFavoriteService() {
    let counter = 0;
    if (this.favoriteItems) {
      for (const favorite of this.favoriteItems) {
        counter += favorite.itemVariations.length;
      }
      this.dataService.sendFavData(counter);
    }
    return counter;
  }

  clearFavorites(username: string) {
    this.favoriteStoreItems = new Map();
    this.favoriteItems = [];
    this.setFavoriteItemsToLocalStorage();
    this.setFavoriteItemsToLocalStorageService([], username);

    return this.favoriteItems;
  }

  constructStorageFavoriteName(username: string) {
    return 'favoriteItems' + '_' + username;
  }
}

