import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ImageCropperComponent, ImageTransform} from 'ngx-image-cropper';
import {DomSanitizer} from '@angular/platform-browser';
// import {MatSliderModule} from '@angular/material/slider';
//
// import { FormsModule } from '@angular/forms';
// import {CommonModule, formatNumber} from '@angular/common';
// import {MatButtonModule} from '@angular/material/button';
// import {MatTooltipModule} from '@angular/material/tooltip';
// import {MatCheckboxModule} from '@angular/material/checkbox';
// import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import {MatIconModule} from '@angular/material/icon';


@Component({
  selector: 'app-app-image-crop-popup',
  // standalone: true,
  // imports: [
  //   CommonModule,
  //   FormsModule,
  //   MatIconModule,
  //   MatButtonModule,
  //   MatTooltipModule,
  //   MatCheckboxModule,
  //   MatProgressSpinnerModule,
  //   MatSliderModule,
  //   ImageCropperModule
  // ],
  templateUrl: './app-image-crop-popup.component.html',
  styleUrl: './app-image-crop-popup.component.scss'
})
export class AppImageCropPopupComponent implements OnInit {
  @ViewChild(ImageCropperComponent)
  imageCropperPopup: ImageCropperComponent;

  max = 2;
  zoom = 0;
  transform: ImageTransform = {};
  dataFileCropedEvent: any = '';

  constructor(
    public dialogRef: MatDialogRef<AppImageCropPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { file: File },
    private sanitizer: DomSanitizer
  ) {
    console.log(data.file);
  }

  ngOnInit() {}

  onSliderChange(value) {
    this.zoom = value;

    console.log(value >= 0, value + 1, 1 - (value / this.max) * -1);
    console.log('------------');
    console.log(value + 1);
    console.log('-------------');
    console.log(1 - (value / this.max) * -1);



    const scale = value >= 0 ? value + 1 : 1 - (value / this.max) * -1;
    this.transform = { scale };
  }

  onClose() {
    this.dialogRef.close();
  }

  onAccept() {
    // console.log(this.imageCropperPopup);
    const event = this.imageCropperPopup.crop();
    console.log(event);
    // this.dialogRef.close(event.base64);
  }

  imageCropped(event: any) {
    console.log(event);
    // this.croppedImageStringFormat(event.blob.size);
    const imageSan = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    // console.log(imageSan);
    this.dialogRef.close(imageSan);

  }


}
