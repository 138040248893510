<div class="page-title-overlap bg-purple pt-4"></div>

<div class="container ">
  <div class="row mt-5 mt-md-0 mt-lg-0">
    <section class="col-lg-8 ">
      <div class="container d-none d-lg-flex justify-content-between align-items-center  pb-4">
        <h3 i18n class="fs-base text-light mb-0">Profile info</h3>
      </div>

      <div class="bg-secondary rounded-3 p-4 mb-5">
        <div class="d-flex align-items-center">
          <div *ngIf="!uploadedFileContent" for="fileUpload" (click)="onUploadImage()" style="width: 6rem;">
            <button i18n mat-button color="primary">
              <mat-icon class="material-symbols-outlined">edit</mat-icon> Avatar
            </button>
          </div>

          <div *ngIf="uploadedFileContent"
               class="img-thumbnail rounded-circle position-relative flex-shrink-0 mx-auto mb-2 mx-md-0 mb-md-0"
               style="width: 6rem;">
            <img class="img-fluid rounded-circle" style="width: 6rem; height: 6rem" alt="Avatar"
                 src="{{uploadedFileContent}}" for="fileUpload" (click)="onUploadImage()" >
          </div>
          <input type="file"
                 #fileUpload
                 id="fileUpload"
                 name="fileUpload"
                 accept="image/*"
                 style="display:none;" />

          <div class="ps-3">
            <button i18n mat-flat-button (click)="onUploadImage()" class=" fs-ms text-muted" >
              <mat-icon class="material-symbols-outlined align-middle">autorenew</mat-icon>
              Change avatar
            </button>
            <button i18n mat-button color="warn" *ngIf="uploadedFileContent"
                    (click)="removeSelectedFile()">
              <mat-icon class="material-icons">clear</mat-icon>Remove
            </button>
            <div class="mt-2 fs-ms text-muted"><span i18n>Upload JPG, GIF or PNG image. 300 x 300 required.</span></div>
          </div>
        </div>
      </div>

      <form [formGroup]="contactsForm" novalidate (ngSubmit)="updateUserInfo()">
        <div class="row gx-4 gy-3">
          <div class="col-6">
            <mat-form-field class="full-width">
              <mat-label i18n >First name</mat-label>
              <input matInput formControlName="firstName">
              <mat-error *ngIf="contactsForm.controls['firstName'].hasError('required')">
                <span i18n >First name is </span> <strong i18n >required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width" >
              <mat-label i18n >Last name</mat-label>
              <input matInput formControlName="lastName">
              <mat-error *ngIf="contactsForm.controls['lastName'].hasError('required')">
                <span i18n >Last name is </span> <strong i18n >required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label i18n >Email address (disabled)</mat-label>
              <input matInput formControlName="email" readonly>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width">
              <mat-label i18n >Phone number</mat-label>
              <input matInput formControlName="phoneNumber">
              <mat-error *ngIf="contactsForm.controls['phoneNumber'].hasError('required')">
                <span i18n >Phone is </span><strong i18n >required</strong>
              </mat-error>
              <mat-error i18n *ngIf="contactsForm.get('phoneNumber').errors">
                Only numbers. Min length is 5 symbols
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12">
            <mat-form-field class="full-width">
              <mat-label i18n >About Me</mat-label>
              <textarea matInput placeholder="Tell us something about you." rows="3"
                        formControlName="description"></textarea>
            </mat-form-field>
          </div>

    <!--      <div class="d-grid col-6">-->
    <!--        <div class="form-check">-->
    <!--          <input class="form-check-input" type="checkbox" id="subscribe_me" checked="">-->
    <!--          <label class="form-check-label" for="subscribe_me">Subscribe me to Newsletter</label>-->
    <!--        </div>-->
    <!--      </div>-->
          <div class="d-grid col-6"></div>
          <div class="d-grid pb-5 col-sm-12 col-md-6 col-lg-6">
            <button i18n mat-raised-button color="primary" class="pull-right" type="submit">Update Profile</button>
          </div>
        </div>
      </form>
    </section>

    <aside class="col-lg-4 ">
      <app-account-menu [accountUserAvatarContent]="this.uploadedFileContent"></app-account-menu>
    </aside>

  <!--  <div class="col-md-4">-->
  <!--    <div class="card card-profile">-->
  <!--      <div class="card-body">-->
  <!--        <h6 class="card-category text-gray">{{userDetails.companyTitle}}</h6>-->
  <!--        <h4 class="card-title">{{userDetails.firstName}} {{userDetails.lastName}}</h4>-->
  <!--        <p class="card-description">-->
  <!--          {{userDetails.aboutMe}} <br/>-->
  <!--          {{userDetails.description}}-->
  <!--        </p>-->
  <!--        <a mat-raised-button href="https://linkedin.com/in/petko-petkov-7b4b9799"-->
  <!--           target="_blank"-->
  <!--           class="btn btn-outline-primary btn-round">-->
  <!--          Follow in linkedin</a>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  </div>


<!--<a target="_blank"-->
   <!--data-color="blue"-->
   <!--id="sendToGoogleButton"-->
   <!--data-toggle="tooltip"-->
   <!--href="{{gmapURL}}">-->

  <!--open in gmaps &lt;!&ndash;open in gmaps  redirect to gmaps navigation&ndash;&gt;-->
<!--</a>-->

<!--<button (click)="constructGmapUrl()">open maps </button>-->
<!--<div>-->
  <!--{{gmapURL}}-->
  <!--=======-->
  <!--{{markerAddress}}-->
<!--</div>-->


<!--</div>-->
<!--</div>-->
<!--<div class="card card-profile">-->
<!--<div id="myLocationMapElementId">-->
  <!--<button type="button" id="myLocationButtonId" class="myLocationButtonClass">111-->
    <!--<div id="you_location_img" class="myLocationImage"> 333</div>-->
  <!--</button>-->
<!--</div>-->

<!--  <google-map-->
<!--    height="500px"-->
<!--    width="100%"-->
<!--    [zoom]="zoom"-->
<!--    [center]="center"-->
<!--    [options]="options"-->
<!--    (mapClick)="click($event)"-->
<!--  >-->
<!--    <map-marker-->
<!--      #markerElem-->
<!--      *ngFor="let marker of markers"-->
<!--      [position]="marker.position"-->
<!--      [label]="marker.label"-->
<!--      [title]="marker.title"-->
<!--      [options]="marker.options"-->
<!--      [icon]=""-->
<!--      (mapClick)="openInfo(markerElem, marker.info, marker.title)"-->
<!--      (mapDragend)="markerDragEnd($event)"-->
<!--    >-->
<!--    </map-marker>-->

<!--    <map-info-window>{{ infoContent }}</map-info-window>-->
<!--  </google-map>-->

</div>


<!--<button (click)="zoomIn()">Zoom in</button>-->
<!--<button (click)="zoomOut()">Zoom out</button>-->
<!--<button (click)="logCenter()">Log center</button>-->
<!--<button (click)="addMarker()">Add marker</button>-->

<!--</div>-->
