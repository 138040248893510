import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AppAddressFormService} from '../app-address-form/app-address-form.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Address} from '../app-address-form/Address';
import {STATES} from '../app-address-form/states-mock-date';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {ContactsService} from '../../modules/about-us/contacts/contacts.service';
import {Contacts} from '../../modules/about-us/contacts/Contacts';
import {AppMessageService} from '../../message/app-message.service';
import {UserService} from '../user.service';
import {User} from '../User';
import {environment} from '../../../environments/environment';
import {AppFileService} from '../../core/app-file-service';
import {FileInfo} from '../../app-item/FileInfo';
import {Subscription} from 'rxjs';
import {AppStorageService} from '../../storageService/app-storage.service';

// const inputtre = document.getElementById('pac-input-start-point');
// const searchBox = new google.maps.places.SearchBox(this.addressSearchBox);

@Component({
  selector: 'app-user-profile',
  templateUrl: './app-user-profile.component.html',
  styleUrls: ['./app-user-profile.component.css'],
  providers: [ UserService ],
  // to change field colors
  // encapsulation: ViewEncapsulation.None
})
export class AppUserProfileComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  // @ViewChild('addressSearch')
  // public searchElementRef: ElementRef;

  readUserSubscription: Subscription;
  updateUserSubscription: Subscription;
  uploadUserImageSubscription: Subscription;

  // addressSearchBox  = '';
  address: Address;
  contactsForm: FormGroup;

  heroForm: FormGroup;

  states = STATES;
  gmapURL: string;



  @ViewChild('fileUpload', {static: false})
  fileUpload: ElementRef;

  markedForDelete: boolean;
  uploadedNewFile: File;

  // @ViewChild('uploadedFileRef', {static: false}) uploadedFileRef: ElementRef;
  uploadedFileContent: string;
  // uploadedFileContentDefaultImage = 'https://firebasestorage.googleapis.com/v0/b/monochrome-94163.appspot.com' +
  '/o/image-default%2Ffunny-green-robot-cartoon-vector-cute-239380736.jpg?alt=media&token=5f099f8b-1f99-4307-ac91-8ae3b3385c43';

  // latlng = new google.maps.LatLng(48.849400, 2.341948);  // PTODO change dynamic current location
  // mapOptions = {
  //   zoom: 11,
  //   center: this.latlng
  // }
  // mapW = this.map;


  constructor(
    private fb: FormBuilder,
    private addressService: AppAddressFormService,
    private storageService: AppStorageService,
    private ngZone: NgZone,
    private contactsService: ContactsService,
    private messageService: AppMessageService,
    private userService: UserService,
    private appFileService: AppFileService) {}

  // googleApiKey = 'AIzaSyCGM8s2Q7d6WWGkhUkr7udmKaKcnXS8VZs&amp;';

  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    // zoomControl: false,
    // scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap', // SATELLITE, HYBRID, TERRAIN
    maxZoom: 15,
    minZoom: 8,
  };
  markers = [];
  infoContent = '';
  // geocoder = new google.maps.Geocoder();
  // request = google.maps.GeocoderRequest;
  markerAddress = '';
  event = google.maps.event;

  inputEl: any;
  searchBox: any;
  contacts = new Contacts();
  user = new User();
  // gMapWaypoints = '';
  // gMapDestination = 'бул. „Петко Ю. Тодоров“ 4-3, София';

  // constructGmapUrl() {
  //   this.gmapURL = 'https://www.google.com/maps/dir/?api=1&' +
  //     'origin=' + this.markerAddress + '&' +
  //     'destination=' + this.gMapDestination + '&' +
  //     'travelmode=driving' + '&' +
  //     // 'avoidTolls=true' + '&' +
  //     // 'dirflg=h' + '&' +
  //     // 'dir_action=navigate' + '&' +
  //     // 'optimize=true' + '&' +
  //     // + 'optimize:true|'
  //     'waypoints='
  //     // + 'optimize:true|'
  //     + this.gMapWaypoints ;
  //
  //   // console.log('passToGoogleAddressUrl___', addressUrl);
  //   // $('#sendToGoogleButton').attr('href', addressUrl);
  // }
  // addLocationButton() {
  //
  //   // google.maps.event.addListener(this.mapW, 'dragend', function() {
  //   //   document.getElementById('#you_location_img').style.backgroundPosition = '0px 0px';
  //   // });
  //
  //   const firstChild = document.getElementById('myLocationButtonId');
  //   firstChild.addEventListener('click', function () {
  //     let imgX = '0';
  //     const animationInterval = setInterval(function () {
  //       if (imgX == '-18') imgX = '0';
  //       else imgX = '-18';
  //       // $('#you_location_img').css('background-position', imgX + 'px 0px');
  //       document.getElementById('#you_location_img').style.backgroundPosition = imgX + 'px 0px';
  //     }, 500);
  //     // findMyGeolocation(animationInterval);
  //   });
  //
  //   let myLocationMapElement = document.getElementById('myLocationMapElementId');
  //   // myLocationMapElement.index = 1;
  //   // this.mapW.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myLocationMapElement);
  // }

  ngOnInit(): void {
    this.contactsForm = this.fb.group({
      company: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15),
        Validators.pattern('[- +()0-9]{5,15}')]],
      description: [''],
    });

    // const searchBox = new google.maps.places.SearchBox(this.addressSearchBox);
    // searchBox.addListener('click', args => );

    this.readUserProfileContactForm();

    // navigator.geolocation.getCurrentPosition(position => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   };
    //   this.addMarker();
    //   console.log('this.center', this.center);
    //
    //   // this.geocodePosition(this.center.lat, this.center.lng);
    // });

    // const self = this;
    // this.inputEl = document.getElementById('pac-input');
    // this.searchBox = new google.maps.places.Autocomplete(self.inputEl);
    // // this.searchBox = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    // this.searchBox.addListener('place_changed', () => {
    //   // this.addListenerPerField(self);
    //   this.addSearchAddressListener();
    // });

    // this.addLocationButton();
  }

  ngOnDestroy(): void {
    if (this.readUserSubscription) {
      this.readUserSubscription.unsubscribe();
    }
    if (this.updateUserSubscription) {
      this.updateUserSubscription.unsubscribe();
    }
    if (this.uploadUserImageSubscription) {
      this.uploadUserImageSubscription.unsubscribe();
    }
  }



  // addLocationButton() {
  //
  //   const firstChild = document.getElementById('myLocationButtonId');
  //   firstChild.addEventListener('click', function () {
  //     let imgX = '0';
  //     const animationInterval = setInterval(function () {
  //       if (imgX == '-18') imgX = '0';
  //       else imgX = '-18';
  //       // $('#you_location_img').css('background-position', imgX + 'px 0px');
  //     }, 500);
  //     // findMyGeolocation(animationInterval);
  //   });
  //
  //   // var myLocationMapElement = document.getElementById('myLocationMapElementId');
  //   // myLocationMapElement.index = 1;
  //   // map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myLocationMapElement);
  // }

  updateUserInfo() {
    if (this.contactsForm.invalid) {
      return;
    }
    if (!this.uploadedNewFile) {
      if (this.markedForDelete && this.user.avatarFile && this.user.avatarFile.name) {
        this.deleteFileUpload(this.user.avatarFile.name);
        this.user.avatarFile.name = '';
        this.user.avatarFile.url = '';
      }
      this.updateUserInfoWithImageName(this.user.avatarFile.name, this.user.avatarFile.url);

    } else {
      if (this.isNewImageUploaded()) {
        if (this.user.avatarFile && this.user.avatarFile.name) {
          this.deleteFileUpload(this.user.avatarFile.name);
        }
        this.uploadImage();
      }
    }


  }
  updateUserInfoWithImageName(newImageName:  string, newImageUrl:  string) {
    this.user.avatar = newImageName;
    this.user.avatarFile.name = newImageName;
    this.user.avatarFile.url = newImageUrl;
    this.user.firstName = this.firstName.value;
    this.user.lastName = this.lastName.value;
    this.user.phoneNumber = this.phoneNumber.value;
    this.user.description = this.description.value;

    this.updateUserSubscription = this.userService.updateUser(this.user)
      .subscribe(response => {
        this.messageService.info($localize`Your account has been successfully updated.`);
      });
    // this.addressService.create(this.contactsForm.value);
  }

  isNewImageUploaded() {
    if (this.user.avatarFile) {
      if (this.user.avatarFile.url !== this.uploadedFileContent) {
        return true;
      }
    }
    return false;
  }

  deleteFileUpload(imageName: string) {
    const path = environment.apiStorePathImage + 'user/' + this.user.email + '/avatar';
    this.appFileService.deleteImage(imageName, path);
  }

  createContactsObjectFromForm(contacts) {
    // this.contacts.company = contacts.company;
    this.user.firstName = contacts.firstName;
    this.user.lastName = contacts.lastName;
    // this.contacts.country = contacts.country;
    // this.contacts.state = contacts.state;
    // this.contacts.city = contacts.city;
    // this.user.address = contacts.address;
    // this.user.postalCode = contacts.postalCode;
    // this.user.phoneNumber = contacts.phoneNumber;
    // this.user.shipping = contacts.shipping;
    this.user.phoneNumber = contacts.phoneNumber;
    this.user.description = contacts.description;
    return this.user;
  }


  uploadImage() {
    const path = environment.apiStorePathImage + 'user/' + this.user.email + '/avatar/' + (this.uploadedNewFile ? this.uploadedNewFile.name : '');
    // const userFile = {} as FileInfo;
    this.user.avatarFile.data = this.uploadedNewFile;
    this.uploadUserImageSubscription = this.appFileService.uploadImage(this.user.avatarFile, path)
      .pipe()
      .subscribe({
        next: (imageRef) => {
          this.uploadedFileContent = imageRef.url;
          this.updateUserInfoWithImageName(imageRef.name, imageRef.url);
          // this.user.avatarInfo = imageRef;
        },
        error: err => {
          console.log(err);
        }
      });
  }

  onUploadImage() {
    const reader = new FileReader();
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];

        this.uploadedNewFile = file;

        reader.readAsDataURL(file);

        reader.onload = () => {
          this.uploadedFileContent = reader.result as string;
        };
        // this.files.push({ data: file, inProgress: false, progress: 0});

      }
      // this.uploadFile(this.files[0]);
      // this.uploadFile(this.selectedFile);
    };
    fileUpload.click();
  }


  markerDragEnd(event: google.maps.MapMouseEvent) {
    console.log(event);
    this.center = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    // this.geocodePosition(event.latLng.lat(), event.latLng.lng());
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    // this.getAddress(this.latitude, this.longitude);
  }

  addSearchAddressListener() {
    this.ngZone.run(() => {
      // get the place result
      const place: google.maps.places.PlaceResult = this.searchBox.getPlace();

      // verify result
      if (place.geometry === undefined || place.geometry === null) {
        return;
      }

// set latitude, longitude and zoom
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
// this.latitude = place.geometry.location.lat();
// this.longitude = place.geometry.location.lng();
      this.zoom = 12;
    });
    this.addMarker();
  }

  // addListenerPerField(self) {
  //   let place: any;
  //   self.places = self.searchBox.getPlace();
  //   if (self.places === '') {
  //     return;
  //   }
  //
  //   place = self.places;
  //
  //   self.map.setCenter(place.geometry.location);
  //
  //   self.searchedGeocode = this.map.getCenter().lat() + ',' + this.map.getCenter().lng();
  //   const icon = {
  //     url: './images/officeMarker.svg',
  //     scaledSize: new google.maps.Size(30, 30),
  //   };
  //   self.markernew = new google.maps.Marker({
  //     position: place.geometry.location,
  //     map: self.map,
  //     icon: icon,
  //     title: 'Current Marked Location'
  //   });
  // }

  logCenter() {
    console.log(JSON.stringify(this.map.getCenter()));
  }

  click(event: google.maps.MapMouseEvent) {
    this.center = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    // this.center = <google.maps.LatLngLiteral>event.latLng;
    console.log(event);
    // this.geocodePosition(event.latLng.lat(), event.latLng.lng());
    // this.addMarker();
  }

  // zoomIn() {
  //   if (this.zoom < this.options.maxZoom) this.zoom++
  // }
  // //
  // zoomOut() {
  //   if (this.zoom > this.options.minZoom) this.zoom--
  // }


  addMarker() {
    // for now only one marker
    this.removeAllMarkers();
    // const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    // const markerGoogle = new google.maps.Marker();
    // markerGoogle.setIcon(image);
    // markerGoogle.setPosition(this.center);
    // searchBox.addListener('places_changed', addListenerPerField);

    // let image = new google.maps.MarkerImage('http://google-maps-icons.googlecode.com/files/home.png');
    const marker = {
      position: {
        lat: this.center.lat, // + ((Math.random() - 0.5) * 2) / 10,
        lng: this.center.lng // + ((Math.random() - 0.5) * 2) / 10,
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: 'Marker info ' + (this.markers.length + 1 + this.markerAddress),
      options: {
        draggable: true,
        animation: google.maps.Animation.DROP,
        icon: {
          url: 'assets/icons/purple-dot.png'
        }
      },

    };
    // this.geocodePosition(marker.position)
    // marker.addEventListener('click', this.toggleBounce(marker));

    this.markers.push(marker);
    // this.markers.push(markerGoogle);
  }

  removeAllMarkers() {
    this.markers = [];
  }

  // geocodePosition(lat, lng) {
  //   const latlng = new google.maps.LatLng(lat, lng);
  //   // return () => {
  //   // return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
  //      this.geocoder.geocode({location: latlng}, (
  //        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
  //           if (status === google.maps.GeocoderStatus.OK) {
  //             // observer.next(results);
  //             // observer.complete();
  //           } else {
  //             console.log(
  //               'Geocoding service: geocode was not successful for the following reason: '
  //               + status
  //             );
  //             // observer.error(status);
  //           }
  //           if (results) {
  //             console.log('11111111111111111111', results[0].geometry.location);
  //             console.log('11111111111111111111', results[0].formatted_address);
  //             // marker.title = responses[0].formatted_address;
  //             this.markerAddress = results[0].formatted_address;
  //             // console.log('coordinates: ' + marker.getPosition().toUrlValue(6));
  //             console.log(results[0].formatted_address);
  //             // infowindow.setContent(marker.title + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
  //             // infowindow.open(map, marker);
  //             // updateMarkerAddress(responses[0].formatted_address);
  //             // resolve(responses.json.results);
  //             this.contactsForm.controls['address'].setValue(results[0].formatted_address);
  //               // city: this.contacts.city,
  //               // state: this.contacts.state,
  //               // postalCode: this.contacts.postalCode,
  //               // shipping: this.contacts.shipping,
  //
  //
  //             this.addMarker();
  //           } else {
  //             // marker.title = 'Cannot determine address at this location.';
  //           }
  //     }));
  //   // });
  // }

  // codeLatLng(callback) {
  //   let latlng = new google.maps.LatLng(40.730885,-73.997383);
  //   if (this.geocoder) {
  //     this.geocoder.geocode({'location': latlng}, function(results, status) {
  //       if (status == google.maps.GeocoderStatus.OK) {
  //         if (results[1]) {
  //           callback(results[1].formatted_address);
  //         } else {
  //           alert("No results found");
  //         }
  //       } else {
  //         alert("Geocoder failed due to: " + status);
  //       }
  //     });
  //   }
  // }

  // geocode(address: string | google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult[]> {
  //   return this.onReady().then(() => {
  //     this.geocoder.geocode(typeof address == "google.maps.GeocoderRequest" ? address: {address: address},
  //       (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
  //         if(status == google.maps.GeocoderStatus.OK) {
  //           return results;
  //         } else {
  //           throw new Error(status.toString());
  //         }
  //       });
  //   });
  // });


  toggleBounce(marker) {
    if (marker.getAnimation() !== null) {
      marker.setAnimation(null);
    } else {
      marker.setAnimation(google.maps.Animation.BOUNCE);
    }
  }

  openInfo(marker: MapMarker, content, title) {
    this.infoContent = content;
    this.info.open(marker);
    // this.toggleBounce(marker);

    // this.contactsForm = this.fb.group({
    //   // company: this.address.company,
    //   // name: new FormControl(this.hero.name, [
    //   //   Validators.required,
    //   //   Validators.minLength(4),
    //   //   forbiddenNameValidator(/bob/i) // <-- Here's how you pass in the custom validator.
    //   // ]),
    //   // alterEgo: new FormControl(this.hero.alterEgo),
    //   // power: new FormControl(this.hero.power, Validators.required)
    // });

    this.contactsForm.setValue({
      company: this.address.company,
      firstName: this.address.firstName,
      lastName: this.address.lastName,
      // address: content,
      // address2: this.address.address2,
      city: this.address.city,
      state: this.address.state,
      postalCode: this.address.postalCode,
      shipping: this.address.shipping,
      description: 'this.address.description',

      // address: {
      //   street: '123 Drew Street'
      // }
    });
    // this.geocodePosition(marker.getPosition(),'');
  }


  readUserProfileContactForm() {
    const currentUser = this.storageService.getUserName();
    // this.address = this.addressService.read(this.authenticationService.currentUser.id);
   this.readUserSubscription = this.userService.findByEmail(currentUser)
     .subscribe(
       result => {
         this.user = result;

          if (this.user == null) {
            this.user = new User();
          }
          if (this.user && this.user.avatarFile) {
            this.uploadedFileContent = this.user.avatarFile.url;
          }
          // .toPromise()
          // .then(data => {

          this.contactsForm.setValue({
            company: 'my company',
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            phoneNumber: this.user.phoneNumber,
            email: this.user.email,
            description: this.user.description,
          });
       });
    // this.address = data;
    console.log(this.contacts);
    console.log(this.contactsForm);
    // });
  }

  removeSelectedFile() {
    this.fileUpload.nativeElement.value = null;
    this.uploadedNewFile = null;
    this.uploadedFileContent = null;
    this.markedForDelete = true;
  }

  get firstName(): any {
    return this.contactsForm.get('firstName');
  }
  get lastName(): any {
    return this.contactsForm.get('lastName');
  }
  get phoneNumber(): any {
    return this.contactsForm.get('phoneNumber');
  }
  get description(): any {
    return this.contactsForm.get('description');
  }
}
