import {Component, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {GoogleMapService} from './google-map.service';
import {MapInfoWindow} from '@angular/google-maps';
// import './google-map.js';
// import {require} from '../../../test';
// import { google } from '@google/maps';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css'],
  providers: [GoogleMapService]
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
  constructor() {

  }


  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  @ViewChild('mapContainer', { static: true }) gmapElement: ElementRef;
  markers = [];

  latlng = new google.maps.LatLng(48.849400, 2.341948);  // PTODO change dynamic current location
  mapOptions = {
    zoom: 11,
    center: this.latlng
  };
  map = new google.maps.Map(document.getElementById('map'), this.mapOptions);

  // const googleMapsClient = require('@google/maps').createClient({
  //   key: 'AIzaSyCGM8s2Q7d6WWGkhUkr7udmKaKcnXS8VZs&callback'
  // });
  //
  // // Geocode an address.
  // googleMapsClient.geocode({
  //                            address: '1600 Amphitheatre Parkway, Mountain View, CA'
  //                          }, function(err, response) {
  //   if (!err) {
  //     console.log(response.json.results);
  //   }
  // });

  // title = 'angular-gmap';

  // map: google.maps.Map;
  // lat = 40.73061;
  // lng = -73.935242;

  // coordinates = new google.maps.LatLng(this.lat, this.lng);

  // mapOptions: google.maps.MapOptions = {
  //   center: this.coordinates,
  //   zoom: 8
  // };
  //
  // marker = new google.maps.Marker({
  //   position: this.coordinates,
  //   map: this.map,
  // });

// map = '';
//   mapInitializer() {
//     this.map = new google.maps.Map(this.gmap.nativeElement,
//    );
//     // this.marker.setMap(this.map);
//   }

  // @ViewChild(AgmMap, { static: false })
  // public agmMap: AgmMap

  // zoom: number = 8;
  // lat: number = 51.673858;
  // lng: number = 7.815982;

  googleApiKey = 'AIzaSyCGM8s2Q7d6WWGkhUkr7udmKaKcnXS8VZs&amp;';

  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  ngOnInit() {
    console.log('11111111111111111111111111111111111111111111111111111111111111111');
    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      console.log('this.center', this.center);
    });
    console.log('this.center22222');

    this.addLocationButton();
  }

  addMarker() {
    this.markers.push({
      position: {
        lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
        lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      options: { animation: google.maps.Animation.BOUNCE },
    });
  }

  // zoomIn() {
  //   if (this.zoom < this.options.maxZoom) this.zoom++
  // }
  //
  // zoomOut() {
  //   if (this.zoom > this.options.minZoom) this.zoom--
  // }
  // ngOnInit(): void {
  //
  //   // this.loadScript();
  //   // console.log('11111111111');
  //   // this.renderMap();
  //
  //
  //   // var mapProp = {
  //   //   center: new google.maps.LatLng(18.5793, 73.8143),
  //   //   zoom: 15,
  //   //   mapTypeId: google.maps.MapTypeId.ROADMAP
  //   // };
  //   // this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
  //
  // }
  ngAfterViewInit() {

    console.log('7777777');
    this.loadMap();
  }


  //
  // loadScript() {
  //   // $('#googleKeyId').attr('src', googleKey);
  //   this.googleApiKey = 'https://maps.googleapis.com/maps/api/js?key=' + this.googleApiKey + '&libraries=places&callback=loadMap';
  //   const script = document.createElement('script');
  //   script.setAttribute('defer', 'true');
  //   script.setAttribute('async', 'true');
  //   script.setAttribute('src', this.googleApiKey);
  //   document.getElementsByTagName('body')[0].appendChild(script);
  // }


  renderMap() {

    // if (!window.document.getElementById('google-map-script')) {
    //   const s = window.document.createElement('script');
    //   s.id = 'google-map-script';
    //   s.type = 'text/javascript';
    //   s.setAttribute('defer', 'true');
    //   s.setAttribute('async', 'true');
    //   // s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDRmqZ-1VD-DbsccElMGtMtlRz9FndbPB4&amp;callback=initMap';
    //   s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCGM8s2Q7d6WWGkhUkr7udmKaKcnXS8VZs';
    //
    //   window.document.body.appendChild(s);
    // }

  }

  loadMap = () => {
    console.log('aaaaaaaaaaaaaaaaa');
    // new google.maps.Map
    // new window['google'].maps.Map
    const map = new window['google'].maps.Map(this.gmapElement.nativeElement, {
      center: {lat: 42.63200, lng: 23.285588},
      zoom: 10
    });

    const marker = new window['google'].maps.Marker({
      position: {lat: 42.63200, lng: 23.285588},
      map: map,
      title: 'Hello World!',
      draggable: true,
      animation: window['google'].maps.Animation.DROP,
    });

    const contentString = '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h3 id="thirdHeading" class="thirdHeading">W3path.com</h3>' +
      '<div id="bodyContent">' +
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>' +
      '</div>' +
      '</div>';

    const infowindow = new window['google'].maps.InfoWindow({
      content: contentString
    });

    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });
  }

  placeMarker(event: google.maps.MapMouseEvent) {
    console.log('eventeventevent', event);
  }


  logCenter() {
    // console.log(JSON.stringify(this.map.getCenter()));
  }
  initMap() {
//     directionsService = new google.maps.DirectionsService();
//     directionsDisplay = new google.maps.DirectionsRenderer();
//
//     geocoder = new google.maps.Geocoder();
//     var latlng = new google.maps.LatLng(48.849400, 2.341948);//PTODO change dynamic current location
//     var mapOptions = {
//       zoom: 11,
//       center: latlng
//     }
//     map = new google.maps.Map(document.getElementById('map'), mapOptions);
//     directionsDisplay.setMap(map);
//
//     // Create the search box and link it to the UI element.
//     var input = document.getElementById('pac-input-start-point');
//     var inputEndPoint = document.getElementById('pac-input-end-point');
//
//     var searchBox = new google.maps.places.SearchBox(input);
//     var searchBoxSecond = new google.maps.places.SearchBox(inputEndPoint);
//
//     searchBox.addListener('places_changed', addListenerPerField);
//     searchBoxSecond.addListener('places_changed', addListenerPerField);
//
//     google.maps.event.addListener(map, 'click', function(event) {
//       var markerCustom = addMarker('', event.latLng, true, true);
//
//       markerCustom.addListener('click', function() {
//         console.log("markerCustom___");
//         geocodePosition(markerCustom, addDragableListener);
//         infowindow.close();
//       });
//
//       geocodePosition(markerCustom, addDragableListener);
//       console.log("initial value when clicked on map to take address___");
//
//       google.maps.event.addListener(markerCustom, 'dragend', function(event) {
//         geocodePosition(markerCustom, addDragableListener);
//       });
//
//       markers.push(markerCustom);
//     });
//
//
//
//     infowindow = new google.maps.InfoWindow({
//       size: new google.maps.Size(150, 50)
//     });
//
//
//
//
//
//     // map.fitBounds(bounds);
//     // calculateRoute();
//     // if (refdossNum) {
//     // loadCodeByAddress(myLocation);
//
// //         }
//     addLocationButton();

  }

  addLocationButton() {

    google.maps.event.addListener(this.map, 'dragend', function() {
      document.getElementById('#you_location_img').style.backgroundPosition = '0px 0px';
    });

    const firstChild = document.getElementById('myLocationButtonId');
    firstChild.addEventListener('click', function () {
      let imgX = '0';
      const animationInterval = setInterval(function () {
        if (imgX == '-18') imgX = '0';
        else imgX = '-18';
        // $('#you_location_img').css('background-position', imgX + 'px 0px');
        document.getElementById('#you_location_img').style.backgroundPosition = imgX + 'px 0px';
      }, 500);
      // findMyGeolocation(animationInterval);
    });

    const myLocationMapElement = document.getElementById('myLocationMapElementId');
    // myLocationMapElement.index = 1;
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myLocationMapElement);
  }

}
