import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {AppAboutUsService} from './app-about-us.service';
import {AboutUs} from '../../admin/admin-about-us/AboutUs';
import {AuthenticationService} from '../../auth/authentication.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {getLocaleId} from '@angular/common';
import {AppStorageService} from '../../storageService/app-storage.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './app-about-us.component.html',
  styleUrls: ['./app-about-us.component.css']
})
export class AppAboutUsComponent implements OnInit, OnDestroy {

  aboutUsSubscription: Subscription;
  aboutUs: AboutUs;
  selectedAboutUsStoreName: string;

  currentLanguage = getLocaleId(this.locale);
  currentDomain = this.storageService.getDomainString();
  currentLoggedUser = this.storageService.getUserName();

  constructor(private route: ActivatedRoute,
              private aboutUsService: AppAboutUsService,
              private storageService: AppStorageService,
              @Inject(LOCALE_ID) public locale: string) {

  }

  ngOnInit(): void {
    this.selectedAboutUsStoreName = this.route.snapshot.paramMap.get('storeName');
    if (this.selectedAboutUsStoreName) {
      this.findAboutUsByStore(this.selectedAboutUsStoreName);
    }



  }

  findAboutUsByStore(storeName: string) {
    this.aboutUsSubscription = this.aboutUsService.readAboutUs(this.currentDomain, storeName, this.currentLanguage)
      .subscribe({
        next: aboutUs => {
          if (aboutUs) {
            this.aboutUs = aboutUs;
          }
        },
        error: error => {

        }
      });
  }

  ngOnDestroy(): void {
    if (this.aboutUsSubscription) {
      this.aboutUsSubscription.unsubscribe();
    }
  }







}
