import {Injectable, Injector} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {CookieSnackbarComponent} from '../cookie-snackbar/cookie-snackbar.component';
import {MatSnackBar, MatSnackBarHorizontalPosition} from '@angular/material/snack-bar';
import {AppStorageService} from '../storageService/app-storage.service';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';

  constructor(private storageService: AppStorageService,
              public dialog: MatDialog,
              private overlay: Overlay,
              private injector: Injector,
              // private cookieSnackbar: CookieSnackbarComponent,
              private snackBar: MatSnackBar
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // const snackBar = this.injector.get(CookieSnackbarComponent);

    if (this.storageService.checkCookie() !== 'true') {
      // this.openDialog();
      this.openSnackBar();
    }

    // this.snackBar.openFromComponent(CookieSnackbarComponent);
    // this.snackBar.open('WARN: This is TEST release. Development is not finished yet and data is not persisted', '');

    return next.handle(request);
  }

  openSnackBar() {
    this.snackBar.openFromComponent(CookieSnackbarComponent, {
      horizontalPosition: this.horizontalPosition,
      panelClass: ['white-snackbar']
    });
  }

  // openSnackbar() {
  //   // Load the given component into the snack-bar.
  //   let snackBarRef = snackbar.openFromComponent(MessageArchivedComponent);
  // }

}
