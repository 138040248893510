import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Store} from './Store';
import {PageWrapperItems} from '../app-item/PageWrapperItems';
import {StoreParamWrapperDTO} from './StoreParamWrapperDTO';
import {StoreWrapperDTO} from './StoreWrapperDTO';

const storeUrl = environment.apiEndpoint + '/api/public/store';
const storeGlobalUrl = environment.apiEndpoint + '/api/public/global';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient) { }


  findStoreByDomainAndNameAndLanguage(domain: string, storeName: string, language: string): Observable<Store> {
    return this.http.get<Store>(storeUrl + '/' + domain + '/' + storeName + '/' + language);
  }

  findStoresByDomain(domain: string, pageIndex: number, pageSize: number): Observable<StoreWrapperDTO> {
    let params = new HttpParams();
    params = params.set('pageIndex', pageIndex);
    params = params.set('pageSize', pageSize);

    return this.http.get<StoreWrapperDTO>(storeUrl + '/' + domain, {params: params});
  }

  findStoreItemsByStoreAndPage(storeName, language, pageName, searchTextParam, orderBy,
                               pageIndex: number, pageSize: number,
                               filterBrandIds: number[], filterColorIds: number[], filterSizeIds: number[],
                               filterPriceMin: number, filterPriceMax: number): Observable<PageWrapperItems> {
    let params = new HttpParams();
    if (searchTextParam) {
      params = params.append('searchBoxText', searchTextParam);
    }
    params = params.append('orderBy', orderBy);
    params = params.append('pageIndex', pageIndex);
    params = params.append('pageSize', pageSize);

    params = params.append('filterBrandIds', filterBrandIds ? filterBrandIds.join(', ') : '');
    params = params.append('filterColorIds', filterColorIds ? filterColorIds.join(', ') : '');
    params = params.append('filterSizeIds', filterSizeIds ? filterSizeIds.join(', ') : '');

    params = params.append('filterPriceMin', filterPriceMin);
    params = params.append('filterPriceMax', filterPriceMax);

    return this.http.get<PageWrapperItems>(storeUrl + '/find/all' + '/' + storeName + '/' + language + '/' + pageName, {params: params});
  }


  loadStoreGlobalData(domain: string, storeName: string,
                      categoryName: string,
                      language: string): Observable<StoreParamWrapperDTO> {
    let params = new HttpParams();
    params = params.append('storeName', storeName);
    params = params.append('categoryName', categoryName);

    return this.http.get<StoreParamWrapperDTO>(storeGlobalUrl + '/data/' + domain + '/' + language, {params: params});
  }
}
