import {Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent, LoadedImage} from 'ngx-image-cropper';
import {DomSanitizer} from '@angular/platform-browser';
import imglyRemoveBackground from '@imgly/background-removal';
// import {Config} from '@imgly/background-removal/src/schema';
import removeBackground, { preload, Config } from '@imgly/background-removal';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {imageBackgroundConfig} from '../app-image-background/image.background';

// import { rembg } from '@remove-background-ai/rembg.js';
// import { dotenv } from 'dotenv';
//
// // API_KEY will be loaded from the .env file
// const API_KEY = process.env.API_KEY;
//
// // log upload and download progress
// const onDownloadProgress = console.log;
// const onUploadProgress = console.log;

// declare var reduceImageSize: any;

@Component({
  selector: 'app-image-cropper',
  templateUrl: './app-image-cropper.component.html',
  styleUrls: ['./app-image-cropper.component.scss']
})
export class AppImageCropperComponent implements OnInit, AfterViewInit {

  croppedImageQuality = 75;
  // croppedImageBackground = '#e0dddd';
  croppedImageBackground = '#ffffff';
  transform: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageSizeKB: number;
  croppedBackgroundImage: any = '';

  removeBackground = false;
  showLoadingSpinner = false;
  spinnerTimerId;
  spinnerTimerCounter = 0;

  @ViewChild(ImageCropperComponent)
  private imageCropperAccess: ImageCropperComponent;

  @ViewChild('fileUpload', { static: true })
  private fileUploadRef: ElementRef;

  uploadedFile: File;
  uploadedFileManage: any = '';
  uploadedFileSizeKB: string;
  spinnerMode: ProgressSpinnerMode = 'determinate';



  constructor( private sanitizer: DomSanitizer) {
  }

  public ngAfterViewInit() {
    // this.cropper = new Cropper(this.imageElement.nativeElement, {
    //   zoomable: false,
    //   scalable: false,
    //   aspectRatio: 1,
    //   crop: () => {
    //     const canvas = this.cropper.getCroppedCanvas();
    //     this.imageDestination = canvas.toDataURL('assets/images/cup/20200411_163217_image/png');
    //   }
    // });
  }

  public ngOnInit() { }


  fileChangeEvent(event: any): void {
    this.removeBackground = false;
    this.imageChangedEvent = event;
    this.uploadedFile = event.target.files[0];
    this.uploadedFileManage = event.target.files[0];
    this.uploadedFileSizeKB = this.formatBytes(this.uploadedFile.size);
  }
  imageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = event.base64;
    this.croppedImageStringFormat(event.blob.size);
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  removeImageBackgroundMethod(event: any) {
      const config: Config = imageBackgroundConfig;
      // console.log(event.target.files);
      let image_src: ImageData | ArrayBuffer | Uint8Array | Blob | URL | string;

      // const reader = new FileReader();
      // reader.readAsDataURL(event.target.files[0]);
      // reader.onload = () => {
      //   image_src = reader.result;
      //
      //
      // };
      // image_src = event.target.files[0];
      image_src = this.croppedImage.changingThisBreaksApplicationSecurity;

      // const url = URL.createObjectURL(event.target.files[0]);
      // console.log(url);
      // const result = imglyRemoveBackground(url, config);
      // console.log(result);

    this.showLoadingSpinner = true;
    this.startTimer();
      imglyRemoveBackground(image_src, config).then((blob: Blob) => {
        // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
        const url = URL.createObjectURL(blob);
        const urlBlob = blob;
        // console.log(url);
        this.croppedBackgroundImage = url;


        this.uploadedFileManage = this.uploadedFile;
        this.showLoadingSpinner = false;
        // this.fileUploadRef.nativeElement.value = this.sanitizer.bypassSecurityTrustUrl(url);
        // this.fileUploadRef.nativeElement.value = null;

        const fileSaveUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        // var reader = new FileReader();
        // reader.readAsArrayBuffer(urlBlob);
        // reader.onload =  function(e){
        //   console.log('DataURL:', e.target.result);
        //   const file = new File(e.target.result, 'filename.jpg');
        // };
        // this.fileUploadRef.nativeElement.files.push(this.sanitizer.bypassSecurityTrustUrl(url));

        const list = new DataTransfer();
        const file = new File([urlBlob], 'filename.jpg');
        list.items.add(file);

        const myFileList = list.files;

        this.fileUploadRef.nativeElement.files = myFileList;
        this.fileUploadRef.nativeElement.click();

        // Create a new File object
        // const myFile = new File(['Hello World!'], 'myFile.txt', {
        //   type: 'text/plain',
        //   lastModified: new Date(),
        // });
        //
        // // Now let's create a DataTransfer to get a FileList
        // const dataTransfer = new DataTransfer();
        // dataTransfer.items.add(myFile);
        // fileInput.files = dataTransfer.files;

        // this.imageChangedEvent = this.sanitizer.bypassSecurityTrustUrl(url);
        // this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(url);
      });


    // rembg({
    //   apiKey: API_KEY,
    //   inputImagePath: './input.png',
    //   onDownloadProgress,
    //   onUploadProgress,
    //   returnBase64: true,
    // }).then(({outputImagePath, cleanup}) => {
    //   console.log(`✅🎉 background removed and saved under path=${outputImagePath}`);
    //   // if called, it will cleanup (remove from disk) your removed background image
    //   // cleanup();
    // });


  }

  removeBackgroundCheckbox(checked) {
    this.removeImageBackgroundMethod(event);

    // reduceImageSize();

    if (checked) {
      this.removeBackground = true;
    } else {
      this.removeBackground = false;
    }
  }

  startTimer() {
    this.spinnerTimerId = setInterval(() => {
      if (this.croppedBackgroundImage) {
        this.spinnerTimerCounter = 0;
        this.clearTimer();
      } else
      if (this.spinnerTimerCounter < 99) {
        this.spinnerTimerCounter++;
      }
    }, 170);
  }

  clearTimer() {
    clearInterval(this.spinnerTimerId);
  }



  formatBytes(bytes, decimals = 1) {
    if (!+bytes) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  changeImageQuality() {
    this.imageCropperAccess.crop();
  }

  validateCroppedFileSizeMax(fileBytes: number) {
    const kiloB = 1024;
    const maxSize = (100 * kiloB);
    return fileBytes >= maxSize;
  }

  croppedImageStringFormat(bytes) {
    this.croppedImageSizeKB = bytes;
    if (!bytes) {
      return '';
    }
    return this.formatBytes(bytes);
  }
}
