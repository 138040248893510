
  <div *ngIf="currentUser" class="bg-white rounded-3 shadow pt-1 mb-5 mb-lg-0">
    <div class="d-md-flex justify-content-between align-items-center text-center text-md-start p-4">
      <div class="d-md-flex align-items-center">
        <div *ngIf="accountUserAvatarContent" class="img-thumbnail rounded-circle position-relative mx-auto" style="width: 6rem;">
<!--          <span class="badge bg-warning position-absolute end-0 mt-n2" title="Reward points" >{{rewardPoints}}</span>-->
          <img class="img-fluid rounded-circle" src="{{accountUserAvatarContent}}" alt="avatar">
        </div>
        <div class="ps-md-3">
          <h3 class="fs-base mb-0">{{accountUserInfo.firstName}}</h3>
          <span class="text-accent fs-sm">{{currentUser}}</span>
        </div>
      </div>
    </div>

<!--    <div class="bg-light px-4 py-3">-->
<!--      <h3 class="fs-sm mb-0 text-muted"></h3>-->
<!--    </div>-->

    <div class="d-lg-block ">
      <ul class="list-unstyled mb-0">
        <li class="border-bottom mb-0">
          <a routerLink="/order-history" routerLinkActive="active"
             class="nav-link d-flex align-items-center px-4 py-4">
             <mat-icon class="material-symbols-outlined me-2 " color="primary">shopping_bag</mat-icon>
            <span i18n>Orders</span>
          </a>
        </li>
        <li class="border-bottom mb-0">
          <a routerLink="/user-profile"
             class=" nav-link d-flex align-items-center px-4 py-4">
            <mat-icon class="material-symbols-outlined me-2 " color="primary">person</mat-icon>
            <span i18n> Profile info</span>
          </a>
        </li>
        <li class="border-bottom mb-0">
          <a routerLink="/address"
             class="nav-link d-flex align-items-center px-4 py-4">
            <mat-icon class="material-symbols-outlined me-2 " color="primary">pin_drop</mat-icon>
            <span i18n>Addresses</span>
          </a>
        </li>
        <li class="border-bottom" >
          <a routerLink="/change-password"
             class=" nav-link d-flex align-items-center px-4 py-4">
            <mat-icon class="material-symbols-outlined me-2 " color="primary">lock</mat-icon>
            <span i18n>Change password</span>
          </a>
        </li>
<!--        <li class="border-bottom mb-0">-->
<!--          <a routerLink="/payment-methods"-->
<!--             class=" nav-link d-flex align-items-center px-4 py-4">-->
<!--            <mat-icon class="material-symbols-outlined me-2 " color="primary">credit_card</mat-icon>-->
<!--            <span>Payment methods</span>-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="border-bottom mb-0">-->
<!--          <a routerLink="/support-tickets"-->
<!--             class=" nav-link d-flex align-items-center px-4 py-4">-->
<!--            <mat-icon class="material-symbols-outlined me-2 " color="primary">support_agent</mat-icon>-->
<!--            <span>Support tickets</span>-->
<!--          </a>-->
<!--        </li>-->
      </ul>

    </div>
  </div>

