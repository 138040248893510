import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class DataService {
  private subject = new BehaviorSubject(0);
  private favSubject = new BehaviorSubject(0);
  private cartSubject = new BehaviorSubject(0);

  sendData(message: number) {
    this.subject.next(message);
  }

  clearData() {
    this.subject.next(0);
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  sendFavData(message: number) {
    this.favSubject.next(message);
  }

  clearFavData() {
    this.favSubject.next(0);
  }

  readFavData(): Observable<any> {
    return this.favSubject.asObservable();
  }

  sendCartData(message: number) {
    this.cartSubject.next(message);
  }

  clearCartData() {
    this.cartSubject.next(0);
  }

  readCartData(): Observable<any> {
    return this.cartSubject.asObservable();
  }

  clearAllData() {
    this.clearData();
    this.clearFavData();
    this.clearCartData();
  }
}
