<h1 mat-dialog-title>Edit Image:</h1>
<div mat-dialog-content>
  <div class="row p-5">
    {{data.file | json}}
    <div class="col-12">
      <image-cropper
        [imageURL]="data.file"
        [autoCrop]="false"
        [transform]="transform"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
  </div>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <label class="example-value-label">{{slider.value}}</label>
  <mat-slider
    class="zoom-slider"
    [min]="(max * -1)"
    [max]="max"
    step="0.1"
  >
    <input matSliderThumb [(ngModel)]="zoom" #slider (input)="onSliderChange(zoom)">
  </mat-slider>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button class="dnet-button-wizard-back" (click)="onClose()">Close</button>
  <button mat-button class="dnet-button-wizard-next" (click)="onAccept()">
    Accept
  </button>
</div>
