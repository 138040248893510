// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// const apiEndpoint = 'https://stage.monochrome-digital.com';
// const apiEndpoint = 'https://mono-dev-bus3yqws7a-lm.a.run.app';
const apiEndpoint = '';

export const environment = {
  firebase: {
    apiKey: 'AIzaSyDW4d-Gi04GUBnbDGrW2rc4LeIw4yc5So8',
    // authDomain: 'regal-module-425612-s9.firebaseapp.com',
    authDomain: 'dev.monochrome-digital.com',
    projectId: 'regal-module-425612-s9',
    storageBucket: 'regal-module-425612-s9.appspot.com',
    messagingSenderId: '513513935052',
    appId: '1:513513935052:web:57117407b12a0860ae7269'
  },
  production: false,
  apiUrl: apiEndpoint
  , apiEndpoint: apiEndpoint
  , apiEndpointImages: apiEndpoint + '/images'
  , apiStorePathImage: '/'
  , apiProtectedRootUrl: '/api/protected'
  , apiAdminRootUrl: '/api/admin'
  , apiPublicRootUrl: '/api/public'

};
