<div *ngIf="!adminAudit.termsAndCondition.title" style="min-height: 350px"
     class="d-flex justify-content-center align-items-center">
  There is no Term And Condition.
</div>

<div *ngIf="adminAudit" style="min-height: 350px" class="px-3 py-3">
  <p> Revision Id: {{adminAudit.id}}</p>
  <p> Revision type: {{adminAudit.revisionType}}</p>
  <p> CreatedOn: {{adminAudit.createdOn}}</p>
  <p> Active: {{adminAudit.termsAndCondition.active}}</p>
  <h3 mat-dialog-title > {{adminAudit.termsAndCondition.title}}</h3>
  <div mat-dialog-content>
    <p [innerHTML]="adminAudit.termsAndCondition.content"></p>
  </div>

  <div mat-dialog-actions class="pt-3 d-flex justify-content-end">
    <button mat-button (click)="onCloseClick()">
      Close
    </button>
  </div>
</div>
