import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TermAndCondition, TermsAndConditionsAudit} from '../TermAndCondition';

@Component({
  selector: 'app-dialog',
  templateUrl: 'app-dialog.html',
  styleUrls: ['app-dialog.scss'],
})
export class AppDialog {


  constructor(public dialogRef: MatDialogRef<AppDialog>,
              @Inject(MAT_DIALOG_DATA) public adminAudit: TermsAndConditionsAudit) {

    console.log('11111', adminAudit);
  }


  onCloseClick(): void {
    this.dialogRef.close();
  }

}
