import {HttpClient} from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
import {ItemView} from '../app-item/ItemView';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

const itemDetailsPublicUrl = environment.apiEndpoint + '/api/public/item/details';

@Injectable()
export class AppItemDetailsService {

  constructor(private http: HttpClient) {
  }



  readItemDetailsById(itemId: number, language: string): Observable<ItemView> {

    console.log('read_Item_Details_By_Id and language', itemId, language);

    return this.http.get<ItemView>(itemDetailsPublicUrl + '/' + itemId + '/' + language);
  }







}

