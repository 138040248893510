import { ItemView } from './ItemView';
import { FileInfo } from './FileInfo';

export const ITEMS: ItemView[] = [
  // {
  //   id: 5,
  //   name: 'Test One',
  //   price: 35,
  //   title: 'Test title one with little more information to show to customer and little more',
  //   subTitle: 'Dog subtitle one',
  //   vendor: 'vendor',
  //   brandName: 'brandName',
  //   description: 'test some description one',
  //   counter: 1,
  //   favorite: false,
  //   availability: true,
  //   rating: 5.0,
  //   guarantee: 3,
  //   availableQuantity: 1,
  //   buyerQuantity: 1,
  //   discountPrice: 0.0,
  //   discount: 0.0,
  //   mainImage: false,
  //   delivery: 0.0,
  //   size: Size.M,
  //   fileInfos: [{
  //     name: 'some name',
  //     size: 100,
  //     id: 1,
  //     mimeType: 'mimetype',
  //     mainImage: false,
  //     createdBy: 'me',
  //     createdOn: '28/03/20022',
  //     imageContent: '',
  //     data: null
  //   }]
  // },
  // {
  //   id: 6,
  //   name: 'Test One',
  //   price: 35,
  //   title: 'Test title two',
  //   subTitle: 'Dog subtitle one',
  //   description: 'test some description one',
  //   vendor: 'vendor',
  //   brandName: 'brandName',
  //   counter: 1,
  //   favorite: false,
  //   availability: true,
  //   rating: 5.0,
  //   guarantee: 3,
  //   availableQuantity: 1,
  //   buyerQuantity: 1,
  //   discountPrice: 0.0,
  //   discount: 0.0,
  //   mainImage: false,
  //   delivery: 0.0,
  //   size: Size.M,
  //   fileInfos: [{
  //     name: 'some name',
  //     size: 100,
  //     id: 1,
  //     mimeType: 'mimetype',
  //     mainImage: false,
  //     createdBy: 'me',
  //     createdOn: '28/03/20022',
  //     imageContent: '',
  //     data: null
  //   }]
  // },
  // {
  //   id: 7,
  //   name: 'Test One',
  //   price: 70000,
  //   title: 'Test title tree',
  //   subTitle: 'Dog subtitle one',
  //   description: 'test some description one',
  //   vendor: 'vendor',
  //   brandName: 'brandName',
  //   counter: 1,
  //   favorite: false,
  //   availability: true,
  //   rating: 5.0,
  //   guarantee: 3,
  //   availableQuantity: 1,
  //   buyerQuantity: 1,
  //   discountPrice: 0.0,
  //   discount: 0.0,
  //   mainImage: false,
  //   delivery: 0.0,
  //   size: Size.M,
  //   fileInfos: [{
  //     name: 'some name',
  //     size: 100,
  //     id: 1,
  //     mimeType: 'mimetype',
  //     mainImage: false,
  //     createdBy: 'me',
  //     createdOn: '28/03/20022',
  //     imageContent: '',
  //     data: null
  //   }]
  // }


];
