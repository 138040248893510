import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {ItemView} from '../app-item/ItemView';
import {ItemVariation} from '../admin/admin-item/ItemVariation';
import {DataService} from '../_layout/data.service';
import {ItemColorVariation} from '../admin/admin-item/ItemColorVariation';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AppMessageService} from '../message/app-message.service';
import {AppStorageService} from '../storageService/app-storage.service';

const cartUrl = environment.apiEndpoint + environment.apiProtectedRootUrl + '/cart';

@Injectable({providedIn: 'root'})
export class AppCartService implements OnInit, OnDestroy {

  constructor(private http: HttpClient,
              private messageService: AppMessageService,
              private dataService: DataService,
              private appStorageService: AppStorageService) {
  }

  cartItems: ItemView[] = [];

  // TODO - use observable - this way load correct data only on refresh - for all pages where this is used
  currentUser: string = this.appStorageService.getUserName();


  clearCart(username: string) {
    this.currentUser = username;
    this.cartItems = [];
    this.setCartItemsToLocalStorageService();
    this.calculateTotalItemsInCart();
  }


  setCartItemsToLocalStorageService() {
    if (!this.cartItems || this.cartItems.length === 0) {
      this.cartItems = [];
    }
    localStorage.setItem(this.constructStorageName(this.currentUser), JSON.stringify(this.cartItems));
  }

  getCartItemsFromLocalStorageService(username: string): ItemView[] {
    const cartStoredItems = localStorage.getItem(this.constructStorageName(username));
    if (cartStoredItems) {
      return JSON.parse(cartStoredItems);
    }
    return null;
  }

  addToCartService(item: ItemView, variation: ItemColorVariation, username: string) {
    this.currentUser = username;
    const buyerQuantity = this._addItemIntoCart(item);
    if (buyerQuantity > 0) {
      this.updateItemQuantityInCartQuery(item.itemVariations[0].itemSizeFormArray[0].id, buyerQuantity, username);
    } else if (buyerQuantity === 0) {
      const itemVariationId = item.itemVariations[0].itemSizeFormArray[0].id;
      this.createCartQuery(itemVariationId);
    }
  }

  // addToCartAccordingStore(addItem: ItemView) {
  //   let existItems = this.cartStoreItems.get(JSON.stringify(addItem.store.id));
  //   if (!existItems) {
  //     existItems = [];
  //     ++addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity;
  //     existItems.push(addItem);
  //     this.cartStoreItems.set(JSON.stringify(addItem.store.id), existItems);
  //
  //     return;
  //   }
  //   const itemFind = existItems.find(it => it.id === addItem.id);
  //   // const colorFind = itemFind.itemVariations.find(color => item.itemVariations.includes(color));
  //   // const sizeFind = colorFind.itemVariations.find(size => item.itemVariations.includes(size));
  //
  //   if (itemFind) {
  //     let colorExist = false;
  //     for (const color of itemFind.itemVariations) {
  //       for (const variation of addItem.itemVariations) {
  //         if (color.selectedItemColor.name === variation.selectedItemColor.name) {
  //           colorExist = true;
  //
  //           let sizeExist = false;
  //           for (const existSize of color.itemSizeFormArray) {
  //             for (const newSize of variation.itemSizeFormArray) {
  //               if (existSize.size.id === newSize.size.id) {
  //                 ++existSize.buyerQuantity;
  //                 sizeExist = true;
  //               }
  //             }
  //           }
  //           if (!sizeExist) {
  //             ++variation.itemSizeFormArray[0].buyerQuantity;
  //             color.itemSizeFormArray.push(variation.itemSizeFormArray[0]);
  //           }
  //         }
  //       }
  //     }
  //     if (!colorExist) {
  //       ++addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity;
  //       itemFind.itemVariations.push(addItem.itemVariations[0]);
  //     }
  //   } else {
  //     ++addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity;
  //     existItems.push(addItem);
  //   }
  // }

  updateItemQuantityInCartQuery(itemVariationId: number, quantity: number, username: string) {
    this.currentUser = username;
    this.setCartItemsToLocalStorageService();
    this.calculateTotalItemsInCart();
    this.updateItemQuantityInCartService(itemVariationId, quantity)
      .subscribe({
        next: value => {
          this.messageService.info($localize`The product has been successfully added to the cart.`);
        }
      });
  }

  getCartItems(username: string) {
    this.cartItems = this.getCartItemsFromLocalStorageService(username);
    if (this.cartItems) {
      this.calculateTotalItemsInCart();
    }
    return this.cartItems;
  }

  removeFromCartService(item: ItemView, itemVariationId: number) {
    const itemIndex = this.cartItems.findIndex(it => it.id === item.id);
    const itemFind = this.cartItems[itemIndex];
    const colorIndex = itemFind.itemVariations.findIndex((product) => {
      return product.itemSizeFormArray.some((s) => {
        return s.id === itemVariationId;
      });
    });
    const colorFind = itemFind.itemVariations[colorIndex];
    const sizeIndex = colorFind.itemSizeFormArray.findIndex(s => s.id === itemVariationId);

    if (colorFind.itemSizeFormArray.length === 1) {
      if (itemFind.itemVariations.length === 1) {
        this.cartItems.splice(itemIndex, 1);
      } else {
        itemFind.itemVariations.splice(colorIndex, 1);
      }
    } else {
      colorFind.itemSizeFormArray.splice(sizeIndex, 1);
    }

    this.setCartItemsToLocalStorageService();
    this.calculateTotalItemsInCart();
    return this.cartItems;
  }

  removeFromCartQuery(item: ItemView, size: ItemVariation, username: string) {
    this.currentUser = username;
    const itemVariationId = size.id;
    this.removeCartService(itemVariationId)
      .subscribe({
        next: removed => {
          this.removeFromCartService(item, itemVariationId);
          this.messageService.info($localize`Item removed from cart`);

          // this.cartItems;
        }
      });
  }

  deleteCartQuery(username: string) {
    this.deleteAllCartService(username)
      .subscribe({
        next: removed => {
        }
      });
  }

  removeCartService(itemVariationId: number): Observable<void> {
    return this.http.delete<void>(cartUrl + '/' + itemVariationId);
  }
  deleteAllCartService(username): Observable<void> {
    let param = new HttpParams();
    param = param.append('username', username);
    return this.http.delete<void>(cartUrl, {params: param});
  }

  createCartService(itemVariationId: number): Observable<void> {
    return this.http.post<void>(cartUrl + '/' + itemVariationId, {});
  }

  updateItemQuantityInCartService(itemVariationId: number, quantity: number): Observable<void> {
    return this.http.put<void>(cartUrl + '/' + itemVariationId + '/' + quantity, {});
  }

  findCartServiceQuery(language: string): Observable<ItemView[]> {
    return this.http.get<ItemView[]>(cartUrl + '/' + language);
  }

  createCartQuery(itemVariationId: number) {
    this.setCartItemsToLocalStorageService();
    this.calculateTotalItemsInCart();
    this.createCartService(itemVariationId)
      .subscribe({
        next: value => {
          this.messageService.info($localize`The product has been successfully added to the cart.`);
        }
      });
  }

  findCartServiceSubscription(language: string, username: string) {
    this.findCartServiceQuery(language)
      .subscribe({
        next: cartItems => {
          this.cartItems = cartItems;
          this.setCartItemsToLocalStorageService();
          this.calculateTotalItemsInCartService();
        }
      });
  }


  checkExist(item: ItemView) {
    const itemFind = this.cartItems.find(it => it.id === item.id);
    if (!itemFind) {
      this.cartItems.push(item);
      return;
    }

  }

  _addItemIntoCart(addItem: ItemView) {
    if (!addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity) {
      addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity = 0;
    }
    // console.log('1111111111111111111111111111111',
    //   addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity);
    ++addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity;
    // console.log('1111111111111111111111111111111222222222222222222222222222222222',
    //   addItem.itemVariations[0].itemSizeFormArray[0].buyerQuantity);

    if (!this.cartItems) {
      this.cartItems = [];
    }
    let buyerQuantity = 0;
    const itemFind = this.cartItems.find(it => it.id === addItem.id);
    if (itemFind) {
      const colorFind = itemFind.itemVariations.find(color => color.selectedItemColor.id === addItem.itemVariations[0].selectedItemColor.id);
      if (colorFind) {
        const sizeFind = colorFind.itemSizeFormArray.find(size => size.id === addItem.itemVariations[0].itemSizeFormArray[0].id);
        if (sizeFind) {
          if (!this.isSufficientAvailability(sizeFind)) {
            this.messageService.warn($localize`The requested quantity is not available`);
            return -1;
          }
          ++sizeFind.buyerQuantity;
          buyerQuantity = sizeFind.buyerQuantity;
        } else {
          colorFind.itemSizeFormArray.push(addItem.itemVariations[0].itemSizeFormArray[0]);
        }
      } else {
        itemFind.itemVariations.push(addItem.itemVariations[0]);
      }
    } else {
      this.cartItems.push(addItem);
    }

    return buyerQuantity;
    // return {buyerQuantity: buyerQuantity, availableQuantity: availableQuantity};
  }

  isSufficientAvailability(sizeFind: ItemVariation) {
    return sizeFind.quantity > sizeFind.buyerQuantity;
  }

  increaseItemAmount(size: ItemVariation) {
    this.checkQuantityNegativeNumber(size.buyerQuantity);
    ++size.buyerQuantity;
  }

  decreaseItemAmount(size: ItemVariation) {
    if (this.checkQuantityNegativeNumber(size.buyerQuantity)) {
      return;
    }
    --size.buyerQuantity;
  }

  onChangeCalculateItemsAmount(size: ItemVariation) {
    if (this.checkQuantityNegativeNumber(size.buyerQuantity)) {
      size.buyerQuantity = 1;
    }
  }

  checkQuantityNegativeNumber(quantity) {
    return quantity <= 1;
  }

  // calculateTotalItemsAmountForAllStores() {
  //   let totalAmount = 0;
  //   // for (const storeList of this.cartItems) {
  //   //   if (storeList) {
  //       totalAmount += this.calculateTotalItemsAmountWithDiscount(this.cartItems);
  //     // }
  //   // }
  //   return totalAmount;
  // }

  // calculateTotalItemsAmountWithDiscount(storeList: ItemView[]) {
  //   let totalAmount = 0;
  //   for (const store of storeList) {
  //     for (const itemVariation of store.itemVariations) {
  //       for (const size of itemVariation.itemSizeFormArray) {
  //         if (size.discountPrice) {
  //           totalAmount += (+size.discountPrice * +size.buyerQuantity);
  //         } else {
  //           totalAmount += (+size.priceValue * +size.buyerQuantity);
  //         }
  //       }
  //     }
  //   }
  //   return totalAmount;
  // }
  calculateTotalItemsAmountBeforeDiscount(storeList: ItemView[]) {
    let totalAmount = 0;
    for (const store of storeList) {
      for (const itemVariation of store.itemVariations) {
        for (const size of itemVariation.itemSizeFormArray) {
          totalAmount += (+size.priceValue * +size.buyerQuantity);
        }
      }
    }
    return totalAmount;
  }
  calculateTotalItemsDiscount(storeList: ItemView[]) {
    let discountAmount = 0;
    for (const store of storeList) {
      for (const itemVariation of store.itemVariations) {
        for (const size of itemVariation.itemSizeFormArray) {
          if (size.discountPrice) {
            discountAmount += ((+size.priceValue) - (+size.discountPrice)) * (+size.buyerQuantity);
          }
        }
      }
    }
    return discountAmount;
  }

  calculateTotalItemsInCart() {
    // this.findStoreItems();
    let counter = 0;
    if (this.cartItems) {
      for (const item of this.cartItems) {
        for (const color of item.itemVariations) {
          for (const size of color.itemSizeFormArray) {
            counter += size.buyerQuantity;
          }
        }
      }
    }
    this.sendCardDataCounter(counter);
    return counter;
  }

  calculateTotalItemsInCartService() {
    let counter = 0;
    if (this.cartItems) {
      for (const item of this.cartItems) {
        for (const color of item.itemVariations) {
          for (const size of color.itemSizeFormArray) {
            counter += size.buyerQuantity;
          }
        }
      }
      this.dataService.sendCartData(counter);
    }
    return counter;
  }


  sendCardDataCounter(counter: number) {
    this.dataService.sendCartData(counter);
  }

  constructStorageName(username: string) {
    return 'cartItems' + '_' + username;
  }

  ngOnDestroy(): void {
    this.currentUser = '';
  }

  ngOnInit(): void {
    this.currentUser = this.appStorageService.getUserName();
  }
}
