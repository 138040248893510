import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {concatMap, from, map, Observable} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {FileInfo} from '../app-item/FileInfo';


@Injectable()
export class AppFileService {

  constructor(private storage: AngularFireStorage,
              private http: HttpClient) {
  }


  uploadImage(imageUpload: FileInfo, storagePath: string): Observable<FileInfo> {
    // const storage = getStorage();
    const storageRef = this.storage.ref(storagePath);
    const uploadTask = this.storage.upload(storagePath, imageUpload.data);
    return from(uploadTask).pipe(
      concatMap(() => storageRef.getDownloadURL()),
      map(url => {
        console.log('url', url);
        imageUpload.url = url;
        imageUpload.name = imageUpload.data.name;

        return imageUpload;
      })
    );

  }


  deleteImage(fileName: string, filePath: string): void {
    const storageRef = this.storage.ref(filePath);
    storageRef.child(fileName).delete();
  }


}

