import {Component} from '@angular/core';

// TODO for deletion @Deprecated - cookie component is standalone. Not included in any module
@Component({
  selector: 'app-cookie',
  templateUrl: './app-cookie.component.html',
  styleUrls: ['./app-cookie.component.css']
})
export class AppCookieComponent {
  private isConsented = false;

  constructor() {
    // this.isConsented = this.getCookie(COOKIE_CONSENT) === '1';
  }

  private getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  private consent(isConsent: boolean, e: any) {
    if (!isConsent) {
      return this.isConsented;
    } else if (isConsent) {
      // this.setCookie(COOKIE_CONSENT, '1', COOKIE_CONSENT_EXPIRE_DAYS);
      this.isConsented = true;
      e.preventDefault();
    }
  }
}
