<button
  mat-icon-button class="{{iconButtonSize}}"
  [color]="color"
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="onClick(i + 1)"
  [matTooltip]="ratingId + 1"
  [showCounter]="showCounter"
  [disabled]="disabledStar"
  [iconButtonSize]="iconButtonSize"
>
  <mat-icon class="material-symbols-outlined">
    {{ showIcon(i) }}
  </mat-icon>

</button>

<mat-error *ngIf="starCount == null || starCount == 0">
  Star count is <strong>required</strong> and cannot be zero
</mat-error>
<p class="body-2" *ngIf="showCounter">
<!--  Your Rating: <span class="body-2">{{ rating }}</span> /-->
<!--  <span class="body-2">{{ starCount }}</span>-->
</p>
