
<div>

  <div id="myLocationMapElementId">
    <button type="button" id="myLocationButtonId" class="myLocationButtonClass">
      <div id="you_location_img" class="myLocationImage" > rtrtrt</div>
    </button>
  </div>

  <div #mapContainer id="googleMapId" style="width:100%;height:500px;" (click)="placeMarker($event)"></div>

</div>



