<div class="card">
  <div class="card-header card-header-primary mt-n3 mx-3 bg-purple">
    <h4 class="card-title text-white">Image Cropper</h4>
    <p class="card-category text-white">Crop image according aspect ration</p>
  </div>

  <div class="container">
    <div class="row mt-3">
      <!-- List of items-->
      <div class="">
        <p>Aspect ratio is 3 / 4 with max size: <strong>100KB</strong></p>
        <p>Cropped file format: <strong>webp</strong></p>
        <p>You can use alternative online tool like google squoosh:
          <a href="https://squoosh.app" target="_blank">https://squoosh.app</a>
        </p>
<!--        For Background remover - You can use some of this free online tools-->
<!--        <br/>-->
<!--        <a href="https://remove.bg/upload" target="_blank">https://remove.bg/upload</a>-->
<!--        <br/>-->
<!--        <a href="https://new.express.adobe.com/tools/remove-background" target="_blank">https://new.express.adobe.com/tools/remove-background</a>-->
<!--        <br/>-->
<!--        https://imgbg.net/remove-background-for-ecommerce-->
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-center">
          <br/>
          <br/>
        </div>
        <input type="file" class="file-input"
               (change)="fileChangeEvent($event)" #fileUpload/>
        <div class="d-flex justify-content-end">
          <div class="" *ngIf="!croppedImage">Please upload file</div>
          <span class="me-2">{{uploadedFileSizeKB}}</span>
          <button mat-fab color="white" style="z-index: 1000" matTooltip="Upload file"
                  (click)="fileUpload.click()">
            <mat-icon class="material-symbols-outlined">upload</mat-icon>
          </button>
        </div>
        <div class="card mt-n4  mb-4">
          <div class="" >
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="3 / 4"
              format="webp"
              [imageQuality]="croppedImageQuality"
              [backgroundColor]="croppedImageBackground"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded($event)"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <!--      <a href="{{croppedImage}}" download>{{fileName}}</a>-->
<!--        Quality: {{croppedImageSizeKB}}-->
        <div class="d-flex justify-content-center">
          <mat-slider
            class=" w-100 mx-3"
            [disabled]="false"
            [max]="100"
            [min]="1"
            [step]="1"
            [discrete]="true">
            <input matSliderThumb
                   [(ngModel)]="croppedImageQuality"
                   (change)="changeImageQuality()"/>
          </mat-slider>
        </div>

        <div class="d-flex justify-content-end">
          <span class="me-2">{{croppedImageStringFormat(croppedImageSizeKB)}}</span>
          <a [href]="croppedImage" download matTooltip="Download file"
             disabled="{{validateCroppedFileSizeMax(croppedImageSizeKB)}}"
             mat-fab color="primary" style="z-index: 1000">
            <mat-icon class="material-symbols-outlined">download</mat-icon>
          </a>
        </div>

        <div class="card mt-n4  mb-4" >
          <img [src]="croppedImage" class="img-fluid"/>
        </div>
      </div>

    </div>

<!--    <hr/>-->
<!--    TODO investigate @remove-background-ai/rembg.js leave only one library-->
    <div class="row" >
      <div class="col" >
<!--        <section >-->
<!--          <mat-checkbox (change)="removeBackgroundCheckbox($event.checked)"-->
<!--                        [color]="'primary'"-->
<!--                        [checked]="removeBackground"-->
<!--                        >Remove Background</mat-checkbox>-->
<!--        </section>-->
  <!--      <mat-spinner ></mat-spinner>-->
        <div class="card mt-4 mb-4" *ngIf="removeBackground">
          <div *ngIf="spinnerTimerCounter > 0 && spinnerTimerCounter < 99">
            Processing;
            <!--        Please wait image is processing;-->
            <br/>
            <mat-progress-spinner *ngIf="spinnerTimerCounter > 0"
                                  class=""
                                  [mode]="spinnerMode"
                                  [value]="spinnerTimerCounter">
            </mat-progress-spinner>
            <div class="spinner-info">{{spinnerTimerCounter}} %</div>
          </div>
          <div *ngIf="spinnerTimerCounter === 99">
            Please wait image is preparing:
            <mat-spinner></mat-spinner>
          </div>

          <img [src]="croppedBackgroundImage"/>
        </div>

      </div>
    </div>
  </div>
</div>
