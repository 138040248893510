import {Component, Input, OnInit} from '@angular/core';
import {AppFavoriteService} from '../app-favorite/app-favorite.service';
import {ContactsService} from '../modules/about-us/contacts/contacts.service';
import {AuthenticationService} from '../auth/authentication.service';
import {Contacts} from '../modules/about-us/contacts/Contacts';
import {environment} from '../../environments/environment';
import {User} from '../user/User';
import {UserService} from '../user/user.service';
import {AppStorageService} from '../storageService/app-storage.service';

@Component({
  selector: 'app-account-menu',
  templateUrl: './app-account-menu.component.html',
  styleUrls: ['./app-account-menu.component.css'],
  providers: [AppFavoriteService]
})
export class AppAccountMenuComponent implements OnInit {

  addressImages = environment.apiEndpointImages;
  currentUser = this.storageService.getUserName();
  rewardPoints: number;
  accountUserInfo = new User();
  @Input() accountUserAvatarContent: string;

  constructor(private storageService: AppStorageService,
              private userService: UserService) {

    this.rewardPoints = 123;
  }


  ngOnInit(): void {
    if (this.currentUser) {
      this.readUserProfileContacts();
    }
  }

  readUserProfileContacts() {
    // this.address = this.addressService.read(this.authenticationService.currentUser.id);
    this.userService.findByEmail(this.currentUser)
      .subscribe(
        result => {
          this.accountUserInfo = result;
          console.log('readSubmitForm', this.accountUserInfo);


          if (this.accountUserInfo == null) {
            this.accountUserInfo = new User();
          }
          this.accountUserAvatarContent = this.accountUserInfo.avatar;

        });
  }


}


