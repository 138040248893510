<!--<label>-->
  <input type="file"
         class="file-input-none"
         (change)="selectFiles($event)"
         #fileUpload />
<!--</label>-->

<button
  mat-fab color="white" matTooltip="Upload file"
  (click)="fileUpload.click()"
>
  <mat-icon class="material-symbols-outlined">upload</mat-icon>
</button>

<!--<input type="file" class="file-input"-->
<!--       (change)="fileUploadEvent($event)" #fileUploadStore />-->
<!--<div class="d-flex justify-content-end">-->
<!--  <button mat-fab color="white" matTooltip="Upload file"-->
<!--          (click)="fileUploadStore.click()">-->
<!--    <mat-icon class="material-symbols-outlined">upload</mat-icon>-->
<!--  </button>-->
<!--</div>-->

<!--<div *ngIf="currentFileUpload" class="progress mt-2">-->
<!--  <div-->
<!--    class="progress-bar progress-bar-info"-->
<!--    role="progressbar"-->
<!--    attr.aria-valuenow="{{ percentage }}"-->
<!--    aria-valuemin="0"-->
<!--    aria-valuemax="100"-->
<!--    [ngStyle]="{ width: percentage + '%' }"-->
<!--  >-->
<!--    {{ percentage }}%-->
<!--  </div>-->
<!--</div>-->
