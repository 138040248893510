<div class="bg-light py-4">
  <div class="container d-lg-flex justify-content-end py-2 py-lg-3">
    <!--    <div class="order-lg-1 pe-lg-4 text-center text-lg-start">-->
    <h1 class="h3 mb-0">
      About Us </h1>
    <!--    </div>-->
  </div>
</div>

<div class="container">
  <div class="row" *ngIf="!aboutUs">
    There is no store info
  </div>

  <div *ngIf="aboutUs"
       class="rounded-3 px-4 py-3 mb-5">

    <div class="row">
      <div class="col">
        <h5 >Store: <span class="">{{selectedAboutUsStoreName}}</span>
          <h6 [innerHTML]="aboutUs.title"></h6>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="aboutUs">
          <span [innerHTML]="aboutUs.content"></span>
        </div>
      </div>
    </div>
  </div>
</div>
