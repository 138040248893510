import {Injectable, OnInit} from '@angular/core';
import {ItemView} from './ItemView';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ITEMS} from './mock-app-item';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {PageWrapperItems} from './PageWrapperItems';
import {ItemParamWrapperDTO} from '../admin/admin-item/ItemParamWrapperDTO';
import {ItemVariation} from '../admin/admin-item/ItemVariation';
import {MessageType} from '../message/MessageType';
import {FilterSettings} from './FilterSettings';

const baseUrl = environment.apiEndpoint + '/api/public/item';
const baseFilterUrl = environment.apiEndpoint + '/api/public/filter';

@Injectable({providedIn: 'root'})
export class AppItemService implements OnInit {

  constructor(private http: HttpClient) {
  }

  item: ItemView;
  items: ItemView[] = ITEMS;

  // loadResources() {
  //    return this.http.get<Item>('/showcase/resources/data/cars-small.json')
  //     .toPromise()
  //     // .then(res => <Item[]> res.data)
  //     // .then(data => { return data; });
  //
  //    return this.items;
  // }

  ngOnInit(): void {
    // this.
  }

  findItemsByPageName(categoryName: string,
                      storeName: string, searchText: string,
                      orderBy: string, language: string,
                      pageIndex: number, pageSize: number,
                      filterBrandIds: number[], filterColorIds: number[], filterSizeIds: number[],
                      filterPriceMin: number, filterPriceMax: number,
                      from: string, isSelected: boolean): Observable<PageWrapperItems> {
    let params = new HttpParams();
    params = params.append('categoryName', categoryName ? categoryName : '');
    params = params.append('storeName', storeName ? storeName : '');
    params = params.append('orderBy', orderBy);
    params = params.append('pageIndex', pageIndex);
    params = params.append('pageSize', pageSize);

    params = params.append('filterBrandIds', filterBrandIds ? filterBrandIds.join(', ') : '');
    params = params.append('filterColorIds', filterColorIds ? filterColorIds.join(', ') : '');
    params = params.append('filterSizeIds', filterSizeIds ? filterSizeIds.join(', ') : '');
    params = params.append('filterPriceMin', filterPriceMin);
    params = params.append('filterPriceMax', filterPriceMax);

    params = params.append('from', from);
    params = params.append('isSelected', isSelected);

    if (searchText) {
      params = params.append('searchBoxText', searchText);
    }
    console.log('find items by page name', params);

    return this.http.get<PageWrapperItems>(baseUrl + '/find/all/' + language, {params: params});
  }
  findStoreItemsByStoreAndPage(storeName, pageName, searchTextParam, orderBy, language): Observable<ItemView[]> {
    let params = new HttpParams();
    // params = params.append('pageName', pageName);
    params = params.append('language', language);
    if (searchTextParam) {
      params = params.append('searchBoxText', searchTextParam);
    }
    console.log('find items by store and page name', params);

    return this.http.get<ItemView[]>(baseUrl + '/find/all' + '/' + pageName + '/' + storeName, {params: params});
  }

  loadParameters(domain: string, categoryName: string, filterSettings: FilterSettings, language: string) {
    let params = new HttpParams();
    params = params.append('categoryName', categoryName);
    params = params.append('filterBrandIds', filterSettings.brandIds ? filterSettings.brandIds.join(', ') : '');
    params = params.append('filterColorIds', filterSettings.colorIds ? filterSettings.colorIds.join(', ') : '');
    params = params.append('filterSizeIds', filterSettings.sizeIds ? filterSettings.sizeIds.join(', ') : '');
    params = params.append('filterPriceMin', filterSettings.priceMin);
    params = params.append('filterPriceMax', filterSettings.priceMax);
    params = params.append('orderBy', filterSettings.orderBy);

    return this.http.get<ItemParamWrapperDTO>(baseFilterUrl + '/load' + '/' + domain + '/' + language, {params: params});
  }

  isThereSeveralPricesForItem(variations: ItemVariation[]) {
    const lowest = this.findLowestPriceForItem(variations);
    const biggest = this.findBiggestPriceForItem(variations);

    // return (lowest.discountPrice ? lowest.discountPrice : lowest.priceValue) <
    //   (biggest.discountPrice ? biggest.discountPrice : biggest.priceValue);

    // if (lowest.discountPrice) {
    //
    //    }
    //   switch (message.type) {
    //     case MessageType.SUCCESS:
    //       return 'success';
    //     case MessageType.ERROR:
    //       return 'error';
    //     default:
    //       return 0;
    //   }
    //
    return lowest < biggest;
  }
  findLowestPriceForItem(variations: ItemVariation[]) {
    const itemVariation = this.findLowestPriceObjectForItem(variations);
    let lowestPrice;
    if (itemVariation.discountPrice) {
      lowestPrice = itemVariation.discountPrice;
    } else {
      lowestPrice = itemVariation.priceValue;
    }
    return lowestPrice;
    // let lowest = variations[0];
    // for (const variation of variations) {
    //   if (variation.discountPrice) {
    //     if (variation.discountPrice < lowest.discountPrice) {
    //       lowest = variation;
    //     }
    //   } else if (variation.priceValue < lowest.priceValue) {
    //     lowest = variation;
    //   }
    // }
    // return lowest;
  }
  findLowestPriceObjectForItem(variations: ItemVariation[]) {
    let lowest = variations[0];
    for (const variation of variations) {
      if (variation.discountPrice) {
        if (lowest.discountPrice) {
          if (parseFloat(variation.discountPrice) < parseFloat(lowest.discountPrice)) {
            lowest = variation;
          }
        } else {
          if (parseFloat(variation.discountPrice) < parseFloat(lowest.priceValue)) {
            lowest = variation;
          }
        }
      } else if (parseFloat(variation.priceValue) < parseFloat(lowest.priceValue)) {
        lowest = variation;
      }
    }
    return lowest;
  }
  findBiggestPriceForItem(variations: ItemVariation[]) {
    const itemVariation = this.findBiggestPriceObjectForItem(variations);
    let biggestPrice;
    // if (itemVariation.discountPrice) {
    //   biggestPrice = itemVariation.discountPrice;
    // } else {
      biggestPrice = itemVariation.priceValue;
    // }
    return biggestPrice;
  }

  findBiggestPriceObjectForItem(variations: ItemVariation[]) {
    let biggest = variations[0];
    for (const variation of variations) {
      if (variation.discountPrice) {
        if (biggest.discountPrice) {
          if (parseFloat(variation.discountPrice) > parseFloat(biggest.discountPrice)) {
            biggest = variation;
          }
        } else {
          if (parseFloat(variation.discountPrice) > parseFloat(biggest.priceValue)) {
            biggest = variation;
          }
        }
      } else
        if (parseFloat(variation.priceValue) > parseFloat(biggest.priceValue)) {
        biggest = variation;
      }
    }
    return biggest;
  }

}

// this.http.get<User[]>(baseUrl + '/users');


