import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {ContactsService} from './contacts.service';
import {FormBuilder, Validators} from '@angular/forms';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {Address} from '../../../user/app-address-form/Address';
import {Contacts} from './Contacts';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
  providers: [ContactsService]
})

export class ContactsComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) mapContacts: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  @ViewChild('addressSearch')
  public searchElementRef: ElementRef;

  address: Address;

  contactForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, Validators.compose([
      Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
    ],
    phoneNumber: [null, Validators.compose([
      Validators.required, Validators.minLength(5), Validators.maxLength(15)])
    ],
    shipping: ['free', Validators.required]
  });

  zoom = 12;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    // zoomControl: false,
    // scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap', // SATELLITE, HYBRID, TERRAIN
    maxZoom: 15,
    minZoom: 8,
  };
  markers = [];
  infoContent = '';
  geocoder = new google.maps.Geocoder();
  // request = google.maps.GeocoderRequest;
  markerAddress = '';
  event = google.maps.event;

  input: any;
  searchBox: any;
  contacts = new Contacts();

  constructor(private fb: FormBuilder,
              private ngZone: NgZone,
              private contactsService: ContactsService) {

  }

  ngOnInit(): void {

    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.addMarker();
      console.log('this.center', this.center);
    });

    const self = this;
    this.input = document.getElementById('pac-input');
    this.searchBox = new google.maps.places.Autocomplete(self.input);
    // this.searchBox = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    this.searchBox.addListener('place_changed', () => {
      // this.addListenerPerField(self);
      this.addSearchAddressListener();
    });

  }

  sendInfo() {
    console.log('form send', this.contactForm);
    console.warn('warmmmm', this.contactForm.value);

    const contact = this.createContactsObjectFromForm(this.contactForm.value);
    this.contactsService.createContact(this.contacts);

  }

  createContactsObjectFromForm(contacts) {
    // this.contacts.company = contacts.company;
    this.contacts.firstName = contacts.firstName;
    this.contacts.lastName = contacts.lastName;
    // this.contacts.country = contacts.country;
    // this.contacts.state = contacts.state;
    // this.contacts.city = contacts.city;
    // this.contacts.address = contacts.address;
    this.contacts.phoneNumber = contacts.phoneNumber;
    return this.contacts;
  }


  markerDragEnd(event: google.maps.MapMouseEvent) {
    console.log(event);
    this.center = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    this.geocodePosition(event.latLng.lat(), event.latLng.lng());
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    // this.getAddress(this.latitude, this.longitude);
  }

  addSearchAddressListener() {
    this.ngZone.run(() => {
      // get the place result
      const place: google.maps.places.PlaceResult = this.searchBox.getPlace();

      // verify result
      if (place.geometry === undefined || place.geometry === null) {
        return;
      }

// set latitude, longitude and zoom
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
// this.latitude = place.geometry.location.lat();
// this.longitude = place.geometry.location.lng();
      this.zoom = 12;
    });
    this.addMarker();
  }

  logCenter() {
    console.log(JSON.stringify(this.mapContacts.getCenter()));
  }

  click(event: google.maps.MapMouseEvent) {
    this.center = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    // this.center = <google.maps.LatLngLiteral>event.latLng;
    console.log(event);
    this.geocodePosition(event.latLng.lat(), event.latLng.lng());
    // this.addMarker();
  }

  addMarker() {
    // for now only one marker
    this.removeAllMarkers();
    // const image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    // const markerGoogle = new google.maps.Marker();
    // markerGoogle.setIcon(image);
    // markerGoogle.setPosition(this.center);
    // searchBox.addListener('places_changed', addListenerPerField);

    // let image = new google.maps.MarkerImage('http://google-maps-icons.googlecode.com/files/home.png');
    const marker = {
      position: {
        lat: this.center.lat, // + ((Math.random() - 0.5) * 2) / 10,
        lng: this.center.lng // + ((Math.random() - 0.5) * 2) / 10,
      },
      label: {
        color: 'red',
        text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: 'Marker info ' + (this.markers.length + 1 + this.markerAddress),
      options: {
        draggable: true,
        animation: google.maps.Animation.DROP,
        icon: {
          url: 'assets/icons/blue-dot.png'
        }
      },

    };
    // this.geocodePosition(marker.position)
    // marker.addEventListener('click', this.toggleBounce(marker));

    this.markers.push(marker);
    // this.markers.push(markerGoogle);
  }

  removeAllMarkers() {
    this.markers = [];
  }

  geocodePosition(lat, lng) {
    const latlng = new google.maps.LatLng(lat, lng);
    // return () => {
    // return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
    this.geocoder.geocode({location: latlng}, (
      (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          // observer.next(results);
          // observer.complete();
        } else {
          console.log(
            'Geocoding service: geocode was not successful for the following reason: '
            + status
          );
          // observer.error(status);
        }
        if (results) {
          console.log('11111111111111111111', results[0].geometry.location);
          console.log('11111111111111111111', results[0].formatted_address);
          // marker.title = responses[0].formatted_address;
          this.markerAddress = results[0].formatted_address;
          // console.log('coordinates: ' + marker.getPosition().toUrlValue(6));
          console.log(results[0].formatted_address);
          // infowindow.setContent(marker.title + "<br>coordinates: " + marker.getPosition().toUrlValue(6));
          // infowindow.open(map, marker);
          // updateMarkerAddress(responses[0].formatted_address);
          // resolve(responses.json.results);
          // this.contactForm.setValue({
          //   // company: this.address.company,
          //   firstName: this.address.firstName,
          //   lastName: this.address.lastName,
          //   address: results[0].formatted_address,
            // address2: this.address.address2,
            // city: this.address.city,
            // state: this.address.state,
            // postalCode: this.address.postalCode,
            // shipping: this.address.shipping,

          // });
          this.addMarker();
        } else {
          // marker.title = 'Cannot determine address at this location.';
        }
      }));
    // });
  }

  openInfo(marker: MapMarker, content, title) {
    this.infoContent = content;
    this.info.open(marker);
    // this.toggleBounce(marker);

    // this.addressForm.setValue({
    //   company: this.address.company,
    //   firstName: this.address.firstName,
    //   lastName: this.address.lastName,
    //   address: content,
    //   address2: this.address.address2,
    //   city: this.address.city,
    //   state: this.address.state,
    //   postalCode: this.address.postalCode,
    //   shipping: this.address.shipping,

      // address: {
      //   street: '123 Drew Street'
      // }
    // });
    // this.geocodePosition(marker.getPosition(),'');
  }



}
