import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Address} from './Address';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Contacts} from '../../modules/about-us/contacts/Contacts';

const addressUrl = environment.apiEndpoint + '/api/admin/contact';

@Injectable()
export class AppAddressFormService {

  constructor(private http: HttpClient) {
  }

  createAddress(address: Address) {

    return this.http.post(addressUrl, address);
  }

  editAddress(address) {

    return this.http.put(addressUrl, address);
  }

  readAddress(contactId: number) {

    return this.http.get<Contacts>(addressUrl + '/' + contactId);
  }

  deleteAddress(addressId: number) {
    return this.http.delete(addressUrl + '/' + addressId);
  }



}
