import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Feed, FeedBody} from '../admin/admin-feed/Feed';

const feedProtectedUrl = environment.apiEndpoint + '/api/protected/feed';

@Injectable({
  providedIn: 'root'
})
export class AppFeedService {

  constructor(private http: HttpClient) { }


  readNotificationsByDomainAndLanguage(domain: string, language: string): Observable<FeedBody[]> {
    return this.http.get<FeedBody[]>(feedProtectedUrl + '/' + domain + '/' + language);
  }

  markAsReadFeed(feedId: number) {
    return this.http.patch(feedProtectedUrl + '/' + feedId, null);
  }
}
