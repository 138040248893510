// import {Config} from '@imgly/background-removal/src/schema';
import removeBackground, { preload, Config } from '@imgly/background-removal';

export const imageBackgroundConfig: Config = {
  // const public_path = location.origin + '/assets/remove-background/'; // the path assets are served from
  // const config = {
  // publicPath: 'https://unpkg.com/browse/@imgly/background-removal@1.3.0/dist/
  //                                                  30488a20752335633069be78f6c980fae93117c8c00f49ec48cfccc20ab747c1',
  // config:
  // publicPath: 'https://unpkg.com/browse/@imgly/background-removal@1.3.0/dist/
  //                                                  30488a20752335633069be78f6c980fae93117c8c00f49ec48cfccc20ab747c1',
  publicPath: location.origin + '/assets/remove-background/',
  // fetchArgs: {
  //   mode: 'no-cors'
  // },
  debug: true,
  model: 'medium',
  progress: (key, current, total) => {
    console.log(`Downloading ${key}: ${current} of ${total}`);
    // caption.value = `${type} ${subtype} ${((current / total) * 100).toFixed(
    //   0
    // )}%`;
  },
  proxyToWorker: false,
  output: {
    format: 'image/webp', // The output format. (Default "image/png")
    quality: 0.9 // The quality. (Default: 0.8)
    // type: 'foreground' | 'background' | 'mask'; // The output type. (Default "foreground")
  }

};

