import {Injectable, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AppCartService} from '../app-cart/app-cart.service';
import {AppFavoriteService} from '../app-favorite/app-favorite.service';
import {Router} from '@angular/router';
import {AppMenu} from '../core/AppMenu';
import {environment} from '../../environments/environment';
import {PageMenu} from '../admin/admin-create-page/PageMenu';
import {AppFeedService} from '../app-feed/app-feed.service';

const adminMenuURL = environment.apiEndpoint + '/api/admin/menu';  // URL to web api
const publicMenuUrl = environment.apiEndpoint + '/api/public/menu';  // URL to web api

@Injectable()
export class AppNavigationService implements OnInit {

  constructor(private http: HttpClient,

              private favoriteService: AppFavoriteService,
              private cartService: AppCartService,
              private appFeedService: AppFeedService,
              private router: Router) {
  }

  url: string;

  ngOnInit(): void {
    this.url = this.router.url;
  }


  readFavoriteQuantity() {
    return this.favoriteService.readQuantity();
  }

  readCartQuantity() {
    return this.cartService.calculateTotalItemsInCart();
  }

  readNotifications(domain: string, language: string) {
    return this.appFeedService.readNotificationsByDomainAndLanguage(domain, language);
  }

  // findMenuPages(domain: string, language: string): Observable<AppMenu[]> {
  //
  //   console.log('find MenuPages', adminMenuURL);
  //
  //   return this.http.get<AppMenu[]>(publicMenuUrl + '/find/all' + '/' + domain + '/' + language);
  // }

  findPagesMenuPublic(domain: string, language: string, storeName?: string) {
    console.log('find Pages Menu Public', domain, language);
    let params = new HttpParams();
    if (storeName) {
      params = params.append('storeName', storeName);
    }
    return this.http.get<PageMenu[]>(publicMenuUrl + '/' + domain + '/' + language, {params: params});
  }


  // findCategoriesMenuPublic(domain: string, storeId?: number) {
  //   console.log('find Pages Menu Admin');
  //   if (!storeId) {
  //     return this.http.get<AdminCategoryDto[]>(categoryMenuUrl + '/' + domain);
  //   }
  //   return this.http.get<AdminCategoryDto[]>(categoryMenuUrl + '/' + domain + '/' + storeId);
  // }

}

